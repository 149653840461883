import * as Actions from "../../actions/app"

const initialState = {
  buys: null,

  loading_buy: false,
  buy: null,

  loading_crud_buy: false,
  crud_buy: false,
  data_crud_buy: null,

  providers_credits: null,

  loading_credits_by_provider: false,
  credits_by_provider: null,

  loading_crud_credit_provider: false,
  crud_credit_provider: false,

  loading_payments_credit_provider: false,
  payments_credit_provider: null,

  loading_crud_pay_credit_provider: false,
  crud_pay_credit_provider: false,

  loading_dues_credit_provider_by_credit: false,
  dues_credit_provider_by_credit: null,

  loading_crud_due_credit_provider: false,
  crud_due_credit_provider: false,

  loading_dues_credit_provider_by_provider: false,
  dues_credit_provider_by_provider: null,

  banks: null,
}

const buysReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ALL_BUYS: {
      return {
        ...state,
        buys: action.payload,
      }
    }
    case Actions.GET_BUY: {
      return {
        ...state,
        loading_buy: action.payload.loading,
        buy: action.payload.data,
      }
    }
    case Actions.CRUD_BUY: {
      return {
        ...state,
        loading_crud_buy: action.payload.loading,
        crud_buy: action.payload.crud,
        data_crud_buy: action.payload.data,
      }
    }
    case Actions.GET_PROVIDERS_CREDITS: {
      return {
        ...state,
        providers_credits: action.payload,
      }
    }
    case Actions.GET_CREDITS_BY_PROVIDER: {
      return {
        ...state,
        loading_credits_by_provider: action.payload.loading,
        credits_by_provider: action.payload.data,
      }
    }
    case Actions.CRUD_CREDIT_PROVIDER: {
      return {
        ...state,
        loading_crud_credit_provider: action.payload.loading,
        crud_credit_provider: action.payload.crud,
      }
    }
    case Actions.GET_PAYMENTS_CREDIT_PROVIDER: {
      return {
        ...state,
        loading_payments_credit_provider: action.payload.loading,
        payments_credit_provider: action.payload.data,
      }
    }
    case Actions.CRUD_PAY_CREDIT_PROVIDER: {
      return {
        ...state,
        loading_crud_pay_credit_provider: action.payload.loading,
        crud_pay_credit_provider: action.payload.crud,
      }
    }
    case Actions.GET_DUES_CREDIT_PROVIDER_BY_CREDIT: {
      return {
        ...state,
        loading_dues_credit_provider_by_credit: action.payload.loading,
        dues_credit_provider_by_credit: action.payload.data,
      }
    }
    case Actions.GET_DUES_CREDIT_PROVIDER_BY_PROVIDER: {
      return {
        ...state,
        loading_dues_credit_provider_by_provider: action.payload.loading,
        dues_credit_provider_by_provider: action.payload.data,
      }
    }
    case Actions.CRUD_CREDIT_DUE_PROVIDER: {
      return {
        ...state,
        loading_crud_due_credit_provider: action.payload.loading,
        crud_due_credit_provider: action.payload.crud
      }
    }
    case Actions.GET_BANKS: {
      return {
        ...state,
        banks: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
export default buysReducer
