import React from "react"
import { AuthRoles } from "components/auth"

export const VehiclesConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/vehicles`,
      component: React.lazy(() => import("./Vehicles")),
    },
  ],
}
