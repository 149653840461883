import React from "react"
import { AuthRoles } from "components/auth"

export const StoresConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/stores`,
      component: React.lazy(() => import("./Stores")),
    },
  ],
}
