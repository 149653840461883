import * as Actions from "../../actions/app"

const initialState = {

  series: null,

  loading_crud_serie: false,
  crud_serie: false,

}

const seriesReducer = function (state = initialState, action) {
  switch (action.type) {

    case Actions.GET_ALL_SERIES: {
      return {
        ...state,
        series: action.payload
      }
    }

    case Actions.CRUD_SERIE: {
      return {
        ...state,
        loading_crud_serie: action.payload.loading,
        crud_serie: action.payload.crud
      }
    }

    default: {
      return state
    }
  }
}
export default seriesReducer
