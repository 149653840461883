import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"

export const GET_ALL_VEHICLES_BRANDS = "[VEHICLES] GET ALL VEHICLES BRANDS"
export const CRUD_VEHICLE_BRAND = "[VEHICLES] CRUD VEHICLE BRAND"

export const GET_ALL_VEHICLES_MODELS = "[VEHICLES] GET ALL VEHICLES MODELS"
export const CRUD_VEHICLE_MODEL = "[VEHICLES] CRUD VEHICLE MODEL"

export const GET_ALL_VEHICLES = "[VEHICLES] GET ALL VEHICLES"
export const GET_VEHICLES_WITH_REQUIREMENTS = "[VEHICLES] GET VEHICLES WITH REQUIREMENTS"
export const GET_VEHICLES_PAGINATED = "[VEHICLES] GET VEHICLES PAGINATED"
export const GET_VEHICLE = "[VEHICLES] GET VEHICLE"
export const CRUD_VEHICLE = "[VEHICLES] CRUD VEHICLE"

export const GET_VEHICLE_OWNERS = "[VEHICLES] GET VEHICLE OWNERS"
export const CRUD_VEHICLE_OWNER = "[VEHICLES] CRUD VEHICLE OWNER"

export const GET_ALL_VEHICLE_ACCESSORIES = "[VEHICLES] GET ALL VEHICLE ACCESSORIES"
export const CRUD_VEHICLE_ACCESSORY = "[VEHICLES] CRUD VEHICLE ACCESSORY"


// marcas vehiculos

export function getAllVehiclesBrands() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/marcavehiculo`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_ALL_VEHICLES_BRANDS, payload: response.data.detalles })
    })
  }
}

export function saveVehicleBrand(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rmarcavehiculo`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_BRAND, payload: { loading: true } })
    toast.info("Guardando marca...", { toastId: "saveVehicleBrand", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_BRAND, payload: { loading: false, crud: true, data: response.data.detalles } })
        toast.update("saveVehicleBrand", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("saveVehicleBrand", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_BRAND, payload: { loading: false, crud: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_BRAND, payload: { loading: false, crud: false, data: null } })
      toast.update("saveVehicleBrand", { autoClose: 4000, type: "error", render: "Error al guardar la marca" })
      console.log(error)
    })
  }
}

export function updateVehicleBrand(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/amarcavehiculo`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_BRAND, payload: { loading: true } })
    toast.info("Actualizando marca...", { toastId: "updateVehicleBrand", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_BRAND, payload: { loading: false, crud: true } })
        toast.update("updateVehicleBrand", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("updateVehicleBrand", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_BRAND, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_BRAND, payload: { loading: false, crud: false } })
      toast.update("updateVehicleBrand", { autoClose: 4000, type: "error", render: "Error al actualizar la marca" })
      console.log(error)
    })
  }
}

export function deleteVehicleBrand(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/emarcavehiculo`, form)
  return (dispatch) => {
    request.then((response) => {
      dispatch({ type: CRUD_VEHICLE_BRAND, payload: { loading: true } })
      toast.info("Actualizando marca...", { toastId: "deleteVehicleBrand", autoClose: false })
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_BRAND, payload: { loading: false, crud: true } })
        toast.update("deleteVehicleBrand", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("deleteVehicleBrand", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_BRAND, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_BRAND, payload: { loading: false, crud: false } })
      toast.update("deleteVehicleBrand", { autoClose: 4000, type: "error", render: "Error al eliminar la marca" })
      console.log(error)
    })
  }
}


// modelos vehiculos

export function getAllVehiclesModels() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/modelovehiculo`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_ALL_VEHICLES_MODELS, payload: response.data.detalles })
    })
  }
}

export function saveVehicleModel(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rmodelovehiculo`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_MODEL, payload: { loading: true } })
    toast.info("Guardando modelo...", { toastId: "saveVehicleModel", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_MODEL, payload: { loading: false, crud: true, data: response.data.detalles } })
        toast.update("saveVehicleModel", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("saveVehicleModel", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_MODEL, payload: { loading: false, crud: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_MODEL, payload: { loading: false, crud: false, data: null } })
      toast.update("saveVehicleModel", { autoClose: 4000, type: "error", render: "Error al guardar el modelo" })
      console.log(error)
    })
  }
}

export function updateVehicleModel(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/amodelovehicul`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_MODEL, payload: { loading: true } })
    toast.info("Actualizando modelo...", { toastId: "updateVehicleModel", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_MODEL, payload: { loading: false, crud: true } })
        toast.update("updateVehicleModel", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("updateVehicleModel", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_MODEL, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_MODEL, payload: { loading: false, crud: false } })
      toast.update("updateVehicleModel", { autoClose: 4000, type: "error", render: "Error al actualizar el modelo" })
      console.log(error)
    })
  }
}

export function deleteVehicleModel(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/emodelovehiculo`, form)
  return (dispatch) => {
    request.then((response) => {
      dispatch({ type: CRUD_VEHICLE_MODEL, payload: { loading: true } })
      toast.info("Eliminando modelo...", { toastId: "deleteVehicleModel", autoClose: false })
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_MODEL, payload: { loading: false, crud: true } })
        toast.update("deleteVehicleModel", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("deleteVehicleModel", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_MODEL, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_MODEL, payload: { loading: false, crud: false } })
      toast.update("deleteVehicleModel", { autoClose: 4000, type: "error", render: "Error al eliminar el modelo" })
      console.log(error)
    })
  }
}


// vehiculos

export function getAllVehicles() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/vehiculo`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_ALL_VEHICLES, payload: response.data.detalles })
    })
  }
}

export function getVehiclesPaginated(page = 1) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/vehiculos?page=${page}`)
  return dispatch =>
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_VEHICLES_PAGINATED, payload: response.data })
    })
}

export function getVehiclesWithRequirements() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/vehiculosrequerimientos`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_VEHICLES_WITH_REQUIREMENTS, payload: response.data.detalles })
    })
  }
}

export function getVehicleById(vehicleId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gvehiculo/${vehicleId}`)
  return (dispatch) => {
    dispatch({ type: GET_VEHICLE, payload: { loading: true } })
    toast.info("Obteniendo vehículo...", { toastId: "getVehicle", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_VEHICLE, payload: { loading: false, data: response.data.detalles } })
        toast.dismiss("getVehicle")
      } else {
        dispatch({ type: GET_VEHICLE, payload: { loading: false, data: null } })
        toast.update("getVehicle", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: GET_VEHICLE, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_VEHICLE, payload: { loading: false, data: null } })
      toast.update("getVehicle", { autoClose: 4000, type: "error", render: "Error al obtener el vehículo" })
      console.log(error)
    })
  }
}

export function saveVehicle(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rvehiculo`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE, payload: { loading: true } })
    toast.info("Guardando vehículo...", { toastId: "saveVehicle", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE, payload: { loading: false, crud: true, data: response.data.detalles } })
        toast.update("saveVehicle", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("saveVehicle", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE, payload: { loading: false, crud: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE, payload: { loading: false, crud: false, data: null } })
      toast.update("saveVehicle", { autoClose: 4000, type: "error", render: "Error al guardar el vehículo" })
      console.log(error)
    })
  }
}

export function updateVehicle(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/avehiculo`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE, payload: { loading: true } })
    toast.info("Actualizando vehículo...", { toastId: "updateVehicle", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE, payload: { loading: false, crud: true } })
        toast.update("updateVehicle", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("updateVehicle", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE, payload: { loading: false, crud: false } })
      toast.update("updateVehicle", { autoClose: 4000, type: "error", render: "Error al actualizar el vehículo" })
      console.log(error)
    })
  }
}

export function updateStatusVehicle(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cvehiculo`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE, payload: { loading: true } })
    toast.info("Actualizando estado...", { toastId: "updateStatusVehicle", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE, payload: { loading: false, crud: true } })
        toast.update("updateStatusVehicle", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("updateStatusVehicle", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE, payload: { loading: false, crud: false } })
      toast.update("updateStatusVehicle", { autoClose: 4000, type: "error", render: "Error al actualizar el estado" })
      console.log(error)
    })
  }
}

export function deleteVehicle(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/evehiculo`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE, payload: { loading: true } })
    toast.info("Eliminando vehículo...", { toastId: "deleteVehicle", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE, payload: { loading: false, crud: true } })
        toast.update("deleteVehicle", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("deleteVehicle", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE, payload: { loading: false, crud: false } })
      toast.update("deleteVehicle", { autoClose: 4000, type: "error", render: "Error al eliminar el vehículo" })
      console.log(error)
    })
  }
}


// detalles vehiculos

export function getVehicleOwnersByVehicle(vehicleId, useLoading = false) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/vpropietarios/${vehicleId}`)
  return (dispatch) => {
    if (useLoading) {
      dispatch({ type: GET_VEHICLE_OWNERS, payload: { loading: true } })
    }
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_VEHICLE_OWNERS, payload: { loading: false, data: response.data.detalles } })
      } else {
        dispatch({ type: GET_VEHICLE_OWNERS, payload: { loading: false, data: null } })
      }
      return setTimeout(() => {
        dispatch({ type: GET_VEHICLE_OWNERS, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_VEHICLE_OWNERS, payload: { loading: false, data: null } })
      toast.error("Error al obtener los propietarios")
      console.log(error)
    })
  }
}

export function saveVehicleOwner(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rpropietario`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_OWNER, payload: { loading: true } })
    toast.info("Guardando propietario...", { toastId: "saveVehicleOwner", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_OWNER, payload: { loading: false, crud: true, data: response.data.detalles } })
        toast.update("saveVehicleOwner", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("saveVehicleOwner", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_OWNER, payload: { loading: false, crud: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_OWNER, payload: { loading: false, crud: false, data: null } })
      toast.update("saveVehicleOwner", { autoClose: 4000, type: "error", render: "Error al guardar el propietario" })
      console.log(error)
    })
  }
}

export function updateStatusVehicleOwner(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cpropietario`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_OWNER, payload: { loading: true } })
    toast.info("Actualizando estado...", { toastId: "updateStatusVehicleOwner", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_OWNER, payload: { loading: false, crud: true } })
        toast.update("updateStatusVehicleOwner", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("updateStatusVehicleOwner", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_OWNER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_OWNER, payload: { loading: false, crud: false } })
      toast.update("updateStatusVehicleOwner", { autoClose: 4000, type: "error", render: "Error al actualizar el estado" })
      console.log(error)
    })
  }
}

export function deleteVehicleOwner(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/epropietario`, form)
  return (dispatch) => {
    request.then((response) => {
      dispatch({ type: CRUD_VEHICLE_OWNER, payload: { loading: true } })
      toast.info("Eliminando propietario...", { toastId: "deleteVehicleOwner", autoClose: false })
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_OWNER, payload: { loading: false, crud: true } })
        toast.update("deleteVehicleOwner", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("deleteVehicleOwner", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_OWNER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_OWNER, payload: { loading: false, crud: false } })
      toast.update("deleteVehicleOwner", { autoClose: 4000, type: "error", render: "Error al eliminar el propietario" })
      console.log(error)
    })
  }
}


// accesorios vehiculos

export function getAllVehicleAccessories() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/vaccesorios`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_ALL_VEHICLE_ACCESSORIES, payload: response.data.detalles })
    })
  }
}

export function saveVehicleAccessory(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rvaccesorios`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_ACCESSORY, payload: { loading: true } })
    toast.info("Guardando accesorio...", { toastId: "saveVehicleAccessory", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_ACCESSORY, payload: { loading: false, crud: true, data: response.data.detalles } })
        toast.update("saveVehicleAccessory", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("saveVehicleAccessory", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_ACCESSORY, payload: { loading: false, crud: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_ACCESSORY, payload: { loading: false, crud: false, data: null } })
      toast.update("saveVehicleAccessory", { autoClose: 4000, type: "error", render: "Error al guardar accesorio" })
      console.log(error)
    })
  }
}

export function updateVehicleAccessory(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/avaccesorios`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_ACCESSORY, payload: { loading: true } })
    toast.info("Actualizando accesorio...", { toastId: "updateVehicleAccessory", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_ACCESSORY, payload: { loading: false, crud: true } })
        toast.update("updateVehicleAccessory", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("updateVehicleAccessory", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_ACCESSORY, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_ACCESSORY, payload: { loading: false, crud: false } })
      toast.update("updateVehicleAccessory", { autoClose: 4000, type: "error", render: "Error al actualiar acccesorio" })
      console.log(error)
    })
  }
}

export function deleteVehicleAccessory(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/evaccesorios`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_VEHICLE_ACCESSORY, payload: { loading: true } })
    toast.info("Eliminando accesorio...", { toastId: "deleteVehicleAccessory", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_VEHICLE_ACCESSORY, payload: { loading: false, crud: true } })
        toast.update("deleteVehicleAccessory", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("deleteVehicleAccessory", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_VEHICLE_ACCESSORY, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_VEHICLE_ACCESSORY, payload: { loading: false, crud: false } })
      toast.update("deleteVehicleAccessory", { autoClose: 4000, type: "error", render: "Error al eliminar accesorio" })
      console.log(error)
    })
  }
}
