import React from 'react';
import { AuthRoles } from 'components/auth';

export const InventoryConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/inventory`,
      component: React.lazy(() => import('./Inventory.js')),
    },
  ],
};
