import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"

export const GET_LOANS_OPENING = "[LOANS OPENING] GET LOANS OPENING"

export const CRUD_LOAN_OPENING = "[LOANS OPENING] CRUD LOAN OPENING"

export const GET_LOAN_OPENING_LOANS = "[LOANS OPENING] GET LOAN OPENING LOANS"

export const GET_LOAN_OPENING_MOVEMENTS = "[LOANS OPENING] GET LOAN OPENING MOVEMENTS"

export const GET_LOAN_OPENING_PAYMENTS = "[LOANS OPENING] GET LOAN OPENING PAYMENTS"

export function getLoansOpening({ page = 1, userId = 0 }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/caja-prestamo?page=${page}&idusuario=${userId}`)
  return dispatch => {
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_LOANS_OPENING, payload: response.data })
    }).catch((error) => {
      toast.error("Error inesperado. No se pudo obtener los registros")
      console.log(error)
    })
  }
}

export function saveLoanOpening(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/caja-prestamo/guardar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status === 200) {
        dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo registrar la apertura")
      console.log(error)
    })
  }
}

export function deleteLoanOpening(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/caja-prestamo/eliminar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: true } })
    toast.info('Eliminando apertura...', { toastId: 'deleteLoanOpening', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje, { toastId: 'deleteLoanOpening', autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: 'deleteLoanOpening', autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo eliminar la apertura", { toastId: 'deleteLoanOpening' })
      console.log(error)
    })
  }
}

export function closeLoanOpening(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/caja-prestamo/cerrar`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status === 200) {
        dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo finalizar la apertura")
      console.log(error)
    })
  }
}

export function revertLoanOpening(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/caja-prestamo/revertir`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: true } })
    toast.info('Revirtiendo cierre...', { toastId: 'revertLoanOpening', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje, { toastId: 'revertLoanOpening', autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: 'revertLoanOpening', autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_LOAN_OPENING, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo revertir el cierre", { toastId: 'revertLoanOpening' })
      console.log(error)
    })
  }
}

export function getLoanOpeningLoans({ loanOpeningId = 0 }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/caja-prestamos?idcaja=${loanOpeningId}`)
  return (dispatch) => {
    dispatch({ type: GET_LOAN_OPENING_LOANS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_LOAN_OPENING_LOANS, payload: { loading: false, data: response.data.detalles } })
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: GET_LOAN_OPENING_LOANS, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_LOAN_OPENING_LOANS, payload: { loading: false, data: null } })
      toast.error("Error inesperado. No se pudo obtener los prestamos de caja")
      console.log(error)
    })
  }
}

export function getLoanOpeningMovements({ loanOpeningId = 0, state = 0 }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/caja-movimientos?idcaja=${loanOpeningId}&estado=${state}`)
  return (dispatch) => {
    dispatch({ type: GET_LOAN_OPENING_MOVEMENTS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_LOAN_OPENING_MOVEMENTS, payload: { loading: false, data: response.data.detalles } })
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: GET_LOAN_OPENING_MOVEMENTS, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_LOAN_OPENING_MOVEMENTS, payload: { loading: false, data: null } })
      toast.error("Error inesperado. No se pudo obtener los ingresos y egresos de caja")
      console.log(error)
    })
  }
}

export function getLoanOpeningPayments({ loanOpeningId = 0 }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/caja-pagos?idcaja=${loanOpeningId}`)
  return (dispatch) => {
    dispatch({ type: GET_LOAN_OPENING_PAYMENTS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_LOAN_OPENING_PAYMENTS, payload: { loading: false, data: response.data.detalles } })
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: GET_LOAN_OPENING_PAYMENTS, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_LOAN_OPENING_PAYMENTS, payload: { loading: false, data: null } })
      toast.error("Error inesperado. No se pudo obtener los pagos de prestamos")
      console.log(error)
    })
  }
}