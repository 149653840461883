import React from "react"
import { AuthRoles } from "components/auth"

export const EstablishmentsComplaintsConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/establishments_complaints`,
      component: React.lazy(() => import("./EstablishmentsComplaints")),
    },
  ],
}
