import * as Actions from '../../actions/app';

const initialState = {
  stores: null,

  loading_crud_store: false,
  crud_store: false,

  loading_products_store: false,
  products_store: null,
  total_pages_products_store: 1,
  total_registers_products_store: 0,

  loading_product_stock_in_stores: false,
  product_stock_in_stores: null,
};

const storesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_STORES: {
      return {
        ...state,
        stores: action.payload,
      };
    }

    case Actions.CRUD_STORE: {
      return {
        ...state,
        loading_crud_store: action.payload.loading,
        crud_store: action.payload.crud,
      };
    }

    case Actions.GET_PRODUCTS_STORE: {
      return {
        ...state,
        loading_products_store: action.payload.loading,
        products_store:
          typeof action.payload.data === 'undefined'
            ? state.products_store
            : action.payload.data.detalles,
        total_pages_products_store:
          typeof action.payload.data === 'undefined'
            ? state.total_pages_products_store
            : action.payload.data.totalPaginas,
        total_registers_products_store:
          typeof action.payload.data === 'undefined'
            ? state.total_registers_products_store
            : action.payload.data.totalRegistros,
      };
    }

    case Actions.GET_PRODUCT_STOCK_IN_STORES: {
      return {
        ...state,
        loading_product_stock_in_stores: action.payload.loading,
        product_stock_in_stores: action.payload.data,
      };
    }

    default: {
      return state;
    }
  }
};

export default storesReducer;
