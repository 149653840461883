import * as Actions from "../../actions/app"

const initialState = {

  person_document_types: null,

  loading_people: false,
  people: null,

  loading_search_people: false,
  searched_people: [],

  loading_crud_person: false,
  crud_person: false,
  data_crud_person: null,

  loading_person: false,
  person: null,

  default_person: null,

  loading_person_by_dni: false,
  person_by_dni: null,

  loading_person_by_ruc: false,
  person_by_ruc: null,

}

const peopleReducer = function (state = initialState, action) {
  switch (action.type) {

    case Actions.GET_PERSON_DOCUMENT_TYPES: {
      return {
        ...state,
        person_document_types: action.payload,
      }
    }

    case Actions.GET_PEOPLE: {
      return {
        ...state,
        loading_people: action.payload.loading,
        people: action.payload.data,
      }
    }

    case Actions.FIND_PEOPLE: {
      return {
        ...state,
        loading_search_people: action.payload.loading,
        searched_people: [...action.payload.data],
      }
    }

    case Actions.CRUD_PERSON: {
      return {
        ...state,
        loading_crud_person: action.payload.loading,
        crud_person: action.payload.crud,
        data_crud_person: action.payload.data,
      }
    }

    case Actions.GET_PERSON: {
      return {
        ...state,
        loading_person: action.payload.loading,
        person: action.payload.data,
      }
    }

    case Actions.GET_DEFAULT_PERSON: {
      return {
        ...state,
        default_person: action.payload,
      }
    }

    case Actions.GET_PERSON_BY_DNI: {
      return {
        ...state,
        loading_person_by_dni: action.payload.loading,
        person_by_dni: action.payload.data,
      }
    }

    case Actions.GET_PERSON_BY_RUC: {
      return {
        ...state,
        loading_person_by_ruc: action.payload.loading,
        person_by_ruc: action.payload.data,
      }
    }

    default: {
      return state
    }

  }
}

export default peopleReducer
