import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"

export const GET_ALL_EMPLOYEES = "[EMPLOYEES] GET ALL EMPLOYEES"
export const CRUD_EMPLOYEE = "[EMPLOYEES] CRUD EMPLOYEE"
export const GET_EMPLOYEES_WITH_USER = "[EMPLOYEES] GET EMPLOYEES WITH USER"

export function getAllEmployees() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/empleado`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalles)
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_ALL_EMPLOYEES, payload: response.data.detalles })
    })
  }
}

export function saveEmployee(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rempleado`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_EMPLOYEE, payload: { loading: true } })
    toast.info("Guardando empleado...", { toastId: "saveEmployee", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_EMPLOYEE, payload: { loading: false, crud: true } })
        toast.update("saveEmployee", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("saveEmployee", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_EMPLOYEE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_EMPLOYEE, payload: { loading: false, crud: false } })
      toast.update("saveEmployee", { autoClose: 4000, type: "error", render: "Error al guardar el empleado" })
      console.log(error)
    })
  }
}

export function updateEmployee(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/aempleado`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_EMPLOYEE, payload: { loading: true } })
    toast.info("Actualizando empleado...", { toastId: "updateEmployee", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_EMPLOYEE, payload: { loading: false, crud: true } })
        toast.update("updateEmployee", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("updateEmployee", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_EMPLOYEE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_EMPLOYEE, payload: { loading: false, crud: false } })
      toast.update("updateEmployee", { autoClose: 4000, type: "error", render: "Error al actualizar el empleado" })
      console.log(error)
    })
  }
}

export function updateStatusEmployee(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cempleado`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_EMPLOYEE, payload: { loading: true } })
    toast.info("Actualizando estado...", { toastId: "updateStatusEmployee", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_EMPLOYEE, payload: { loading: false, crud: true } })
        toast.update("updateStatusEmployee", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("updateStatusEmployee", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_EMPLOYEE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_EMPLOYEE, payload: { loading: false, crud: false } })
      toast.update("updateStatusEmployee", { autoClose: 4000, type: "error", render: "Error al actualizar el estado" })
      console.log(error)
    })
  }
}

export function deleteEmployee(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/eempleado`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_EMPLOYEE, payload: { loading: true } })
    toast.info("Eliminando empleado...", { toastId: "deleteEmployee", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_EMPLOYEE, payload: { loading: false, crud: true } })
        toast.update("deleteEmployee", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("deleteEmployee", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_EMPLOYEE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_EMPLOYEE, payload: { loading: false, crud: false } })
      toast.update("deleteEmployee", { autoClose: 4000, type: "error", render: "Error al eliminar el empleado" })
      console.log(error)
    })
  }
}

export function getEmployeesWithUser() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/uempleado`)
  return (dispatch) => {
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_EMPLOYEES_WITH_USER, payload: response.data.detalles })
    })
  }
}
