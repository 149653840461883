import React from 'react';
import { AuthRoles } from "components/auth";

export const LoanOpeningExpensesConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/loan_opening_expenses`,
      component: React.lazy(() => import('./LoanOpeningExpenses'))
    }
  ]
};