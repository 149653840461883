
export const userTypes = [
  {
    id: 1,
    name: 'Usuario'
  },
  {
    id: 2,
    name: 'Empleado'
  }
]

export const userRoles = [
  {
    id: 1,
    role: 'super-admin',
    name: 'Super-Administrador'
  },
  {
    id: 2,
    role: 'admin',
    name: 'Administrador'
  },
  {
    id: 3,
    role: 'atm',
    name: 'Cajero'
  },
  {
    id: 4,
    role: 'seller',
    name: 'Vendedor'
  },
  {
    id: 5,
    role: 'tech',
    name: 'Técnico'
  },
  {
    id: 6,
    role: 'claimer',
    name: 'Reclamos'
  },
  {
    id: 7,
    role: 'admin-complaints',
    name: 'Administrador-Reclamos'
  }
]
