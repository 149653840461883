import * as Actions from "../../actions/app"
const initialState = {

  motives: null,

  // entradas

  entries: null,
  total_pages_entries: 1,
  total_registers_entries: 0,

  loading_entry: false,
  entry: null,

  loading_crud_entry_header: false,
  crud_entry_header: false,
  data_crud_entry_header: null,

  loading_crud_entry: false,
  crud_entry: false,
  data_crud_entry: null,

  loading_crud_entry_item: false,
  crud_entry_item: false,

  loading_entry_items: false,
  entry_items: null,

  loading_finish_entry_detail: false,
  finish_entry_detail: false,

  // salidas

  outputs: null,
  total_pages_outputs: 1,
  total_registers_outputs: 0,

  loading_output: false,
  output: null,

  loading_crud_output_header: false,
  crud_output_header: false,
  data_crud_output_header: null,

  loading_crud_output: false,
  crud_output: false,
  data_crud_output: null,

  loading_crud_output_item: false,
  crud_output_item: false,

  loading_output_items: false,
  output_items: null,

  loading_finish_output_detail: false,
  finish_output_detail: false,

}

const entriesAndOutputsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_MOTIVES: {
      return {
        ...state,
        motives: action.payload,
      }
    }

    // entradas

    case Actions.GET_ENTRIES: {
      return {
        ...state,
        entries: action.payload.detalles,
        total_pages_entries: action.payload.totalPaginas,
        total_registers_entries: action.payload.totalRegistros
      }
    }

    case Actions.GET_ENTRY: {
      return {
        ...state,
        loading_entry: action.payload.loading,
        entry: action.payload.data,
      }
    }

    case Actions.CRUD_ENTRY_HEADER: {
      return {
        ...state,
        loading_crud_entry_header: action.payload.loading,
        crud_entry_header: action.payload.crud,
        data_crud_entry_header: action.payload.data,
      }
    }

    case Actions.CRUD_ENTRY: {
      return {
        ...state,
        loading_crud_entry: action.payload.loading,
        crud_entry: action.payload.crud,
      }
    }

    case Actions.CRUD_ENTRY_ITEM: {
      return {
        ...state,
        loading_crud_entry_item: action.payload.loading,
        crud_entry_item: action.payload.crud
      }
    }

    case Actions.GET_ENTRY_ITEMS: {
      return {
        ...state,
        loading_entry_items: action.payload.loading,
        entry_items: action.payload.data
      }
    }

    case Actions.FINISH_ENTRY_DETAIL: {
      return {
        ...state,
        loading_finish_entry_detail: action.payload.loading,
        finish_entry_detail: action.payload.crud,
      }
    }

    // salidas

    case Actions.GET_OUTPUTS: {
      return {
        ...state,
        outputs: action.payload.detalles,
        total_pages_outputs: action.payload.totalPaginas,
        total_registers_outputs: action.payload.totalRegistros
      }
    }

    case Actions.GET_OUTPUT: {
      return {
        ...state,
        loading_output: action.payload.loading,
        output: action.payload.data,
      }
    }

    case Actions.CRUD_OUTPUT_HEADER: {
      return {
        ...state,
        loading_crud_output_header: action.payload.loading,
        crud_output_header: action.payload.crud,
        data_crud_output_header: action.payload.data,
      }
    }

    case Actions.CRUD_OUTPUT: {
      return {
        ...state,
        loading_crud_output: action.payload.loading,
        crud_output: action.payload.crud,
      }
    }

    case Actions.CRUD_OUTPUT_ITEM: {
      return {
        ...state,
        loading_crud_output_item: action.payload.loading,
        crud_output_item: action.payload.crud
      }
    }

    case Actions.GET_OUTPUT_ITEMS: {
      return {
        ...state,
        loading_output_items: action.payload.loading,
        output_items: action.payload.data
      }
    }

    case Actions.FINISH_OUTPUT_DETAIL: {
      return {
        ...state,
        loading_finish_output_detail: action.payload.loading,
        finish_output_detail: action.payload.crud,
      }
    }

    default: {
      return state
    }
  }
}
export default entriesAndOutputsReducer
