import axios from "axios"
import * as Actions from "components/auth/store/actions"
import { toast } from "react-toastify"

export const GET_ESTABLISHMENT = "[ESTABLISHMENT] GET ESTABLISHMENT"
export const CRUD_ESTABLISHMENT = "[ESTABLISHMENT] CRUD ESTABLISHMENT"

export const GET_BRANCHES = "[ESTABLISHMENT] GET BRANCHES"
export const CRUD_BRANCH = "[ESTABLISHMENT] CRUD BRANCH"

export const CRUD_OPTIONS_BRANCH = "[ESTABLISHMENT] CRUD OPTIONS BRANCH"

export const GET_BANK_ACCOUNTS = "[ESTABLISHMENT] GET BANK ACCOUNTS"
export const CRUD_BANK_ACCOUNT = "[ESTABLISHMENT] CRUD BANK ACCOUNT"

export function getEstablishment() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/establecimiento`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_ESTABLISHMENT, payload: response.data.detalles })
    })
}

export function saveEstablishment(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/raestablecimiento`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_ESTABLISHMENT, payload: true })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_ESTABLISHMENT, payload: false })
      }, 50)
    })
}

export function getBranches() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/esucursal`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_BRANCHES,
        payload: response.data.detalles === null ? null : response.data.detalles,
      })
    })
}

export function saveBranch(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/resucursal`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_BRANCH, payload: true })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_BRANCH, payload: false })
      }, 50)
    })
}

export function updateBranch(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/aesucursal`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_BRANCH, payload: true })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_BRANCH, payload: false })
      }, 50)
    })
}

export function deleteBranch(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/eesucursal`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      return dispatch({
        type: CRUD_BRANCH,
        payload: response.data.mensaje ? response.data.mensaje : null,
      })
    })
}

export function saveBranchVouchersLogo(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/ropcioneslogo`, form)
  return (dispatch) => {
    toast.info("Guardando logo...", { toastId: "saveBranchVouchersLogo", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_OPTIONS_BRANCH, payload: true })
        toast.update("saveBranchVouchersLogo", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("saveBranchVouchersLogo", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_OPTIONS_BRANCH, payload: false })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_OPTIONS_BRANCH, payload: false })
      toast.update("saveBranchVouchersLogo", { autoClose: 4000, type: "error", render: "Error al registrar el logo" })
      console.log(error)
    })
  }
}

export function getBankAccounts() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gcuentasbancarias`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_BANK_ACCOUNTS, payload: response.data.detalles })
    })
}

export function saveBankAccount(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rcuentabancaria`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_BANK_ACCOUNT, payload: true })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_BANK_ACCOUNT, payload: false })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_BANK_ACCOUNT, payload: false })
      console.log(error)
    })
}

export function deleteBankAccount(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/ecuentabancaria`, form)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_BANK_ACCOUNT, payload: true })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_BANK_ACCOUNT, payload: false })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_BANK_ACCOUNT, payload: false })
      console.log(error)
    })
}