import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { getStringDate } from "components/utils"

export const START_COUNTDOWN = '[VENCIMIENTO DE CONTRATO] START COUNTDOWN';
export const UPDATE_TIME = '[VENCIMIENTO DE CONTRATO] UPDATE TIME';
export const TIME_ENDED = '[VENCIMIENTO DE CONTRATO] TIME ENDED';

export const GET_SUMMARY_USER_BY_DATE = "[DASHBOARD] GET SUMMARY USER BY DATE"
export const GET_HISTOGRAM_SALES_USER = "[DASHBOARD] GET HISTOGRAM SALES USER"
export const GET_SUMMARY_LOANS = "[DASHBOARD] GET SUMMARY LOANS"


export function startCountdown() {
  return {
    type: START_COUNTDOWN,
  };
}

export function updateTime(time) {
  return {
    type: UPDATE_TIME,
    payload: time,
  };
}

export function timeEnded() {
  return {
    type: TIME_ENDED,
  };
}

export function getSummaryUserByDate({ userId = 0, begin = getStringDate(), end = getStringDate(), userRole = 'seller' }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/resumenusuario?idusuario=${userId}&inicio=${begin}&fin=${end}&rol=${userRole}`
  )
  return (dispatch) => {
    dispatch({ type: GET_SUMMARY_USER_BY_DATE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_SUMMARY_USER_BY_DATE, payload: { loading: false, data: response.data.detalles } })
      return setTimeout(() => {
        dispatch({ type: GET_SUMMARY_USER_BY_DATE, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_SUMMARY_USER_BY_DATE, payload: { loading: false, data: null } })
      toast.error("Error al obtener registros...")
      console.log(GET_SUMMARY_USER_BY_DATE, error)
    })
  }
}

export function getHistogramSalesByUser({ userId = 0, userRole = 'seller', month = '' }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/histogramaventasusuario?idusuario=${userId}&rol=${userRole}&mes=${month}`
  )
  return (dispatch) => {
    dispatch({ type: GET_HISTOGRAM_SALES_USER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_HISTOGRAM_SALES_USER, payload: { loading: false, data: response.data.detalles } })
      return setTimeout(() => {
        dispatch({ type: GET_HISTOGRAM_SALES_USER, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_HISTOGRAM_SALES_USER, payload: { loading: false, data: null } })
      toast.error("Error al obtener registros...")
      console.log(GET_HISTOGRAM_SALES_USER, error)
    })
  }
}

export function getSummaryLoans() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/resumencobranzas`)
  return (dispatch) => {
    dispatch({ type: GET_SUMMARY_LOANS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_SUMMARY_LOANS, payload: { loading: false, data: response.data.detalles } })
      return setTimeout(() => {
        dispatch({ type: GET_SUMMARY_LOANS, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_SUMMARY_LOANS, payload: { loading: false, data: null } })
      toast.error("Error al obtener registros...")
      console.log(GET_SUMMARY_LOANS, error)
    })
  }
}
