import * as Actions from "../../actions/app"

const initialState = {

  operating_expenses: null,

  loading_crud_operating_expense: false,
  crud_operating_expense: false,

  loading_utility: false,
  utility_sales: null,
  utility_customers_payments: null,
  utility_providers_payments: null,
  utility_incomes: null,
  utility_expenses: null,
  utility_products: null,
  utility_payments_types: null,

  amounts: null,

  loading_sales_data_histogram: false,
  sales_data_histogram: null,

  loading_crud_expense_month: false,
  crud_expense_month: false,

  loading_month_history_histogram: false,
  month_history_histogram: null,

}
const operatingExpensesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ALL_OPERATING_EXPENSES: {
      return {
        ...state,
        operating_expenses: action.payload
      }
    }
    case Actions.CRUD_OPERATING_EXPENSES: {
      return {
        ...state,
        loading_crud_operating_expense: action.payload.loading,
        crud_operating_expense: action.payload.crud,
      }
    }
    case Actions.GET_UTILITY_OPERATING_EXPENSES: {
      return {
        ...state,
        loading_utility: action.payload.loading,
        utility_sales: action.payload.data?.ventas,
        utility_customers_payments: action.payload.data?.pagos_clientes,
        utility_providers_payments: action.payload.data?.pagos_proveedores,
        utility_incomes: action.payload.data?.ingresos,
        utility_expenses: action.payload.data?.egresos,
        utility_products: action.payload.data?.productos,
        utility_payments_types: action.payload.data?.tipos_formas_pagos,
        amounts: action.payload.data?.importes,
      }
    }
    case Actions.GET_SALES_DATA_HISTOGRAM_YEAR: {
      return {
        ...state,
        loading_sales_data_histogram: action.payload.loading,
        sales_data_histogram: action.payload.data
      }
    }
    case Actions.CRUD_EXPENSES_OF_MONTH: {
      return {
        ...state,
        loading_crud_expense_month: action.payload.loading,
        crud_expense_month: action.payload.crud,
      }
    }
    case Actions.GET_MONTH_HISTORY_HISTOGRAM_YEAR: {
      return {
        ...state,
        loading_month_history_histogram: action.payload.loading,
        month_history_histogram: action.payload.data
      }
    }
    default: {
      return state
    }
  }
}
export default operatingExpensesReducer
