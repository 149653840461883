import * as Actions from "../../actions/app"

const initialState = {

  loading_crud_establishment_complaint: false,
  crud_establishment_complaint: false,

  establishments_complaints: null,

  loading_crud_complaint: false,
  crud_complaint: false,

  loading_complaints: false,
  complaints: null,

  loading_crud_user_establishment_complaint: false,
  crud_user_establishment_complaint: false,

  loading_users_establishment_complaint: false,
  users_establishment_complaint: null,

}

const complaintsBookReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CRUD_ESTABLISHMENT_COMPLAINT: {
      return {
        ...state,
        loading_crud_establishment_complaint: action.payload.loading,
        crud_establishment_complaint: action.payload.crud,
      }
    }
    case Actions.GET_ESTABLISHMENTS_COMPLAINTS: {
      return {
        ...state,
        establishments_complaints: action.payload,
      }
    }
    case Actions.CRUD_COMPLAINT: {
      return {
        ...state,
        loading_crud_complaint: action.payload.loading,
        crud_complaint: action.payload.crud,
      }
    }
    case Actions.GET_COMPLAINTS: {
      return {
        ...state,
        loading_complaints: action.payload.loading,
        complaints: action.payload.data,
      }
    }
    case Actions.CRUD_USER_ESTABLISHMENT_COMPLAINT: {
      return {
        ...state,
        loading_crud_user_establishment_complaint: action.payload.loading,
        crud_user_establishment_complaint: action.payload.crud,
      }
    }
    case Actions.GET_USERS_ESTABLISHMENT_COMPLAINT: {
      return {
        ...state,
        loading_users_establishment_complaint: action.payload.loading,
        users_establishment_complaint: action.payload.data,
      }
    }
    default: {
      return state
    }
  }
}

export default complaintsBookReducer
