import * as Actions from "../../actions/app"

const initialState = {

  proformas: null,
  total_pages_proformas: 1,
  total_registers: 0,

  loading_proforma: false,
  proforma: null,

  loading_crud_proforma_header: false,
  crud_proforma_header: false,
  data_crud_proforma_header: null,

  loading_crud_proforma: false,
  crud_proforma: false,
  data_crud_proforma: null,

  loading_crud_proforma_item: false,
  crud_proforma_item: false,

  loading_proforma_items: false,
  proforma_items: null,

  loading_finish_proforma_detail: false,
  finish_proforma_detail: false,

}

const proformaReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PROFORMAS: {
      return {
        ...state,
        proformas: action.payload.detalles,
        total_pages_proformas: action.payload.totalPaginas,
        total_registers: action.payload.totalRegistros
      }
    }

    case Actions.GET_PROFORMA: {
      return {
        ...state,
        loading_proforma: action.payload.loading,
        proforma: action.payload.data,
      }
    }

    case Actions.CRUD_PROFORMA_HEADER: {
      return {
        ...state,
        loading_crud_proforma_header: action.payload.loading,
        crud_proforma_header: action.payload.crud,
        data_crud_proforma_header: action.payload.data,
      }
    }

    case Actions.CRUD_PROFORMA: {
      return {
        ...state,
        loading_crud_proforma: action.payload.loading,
        crud_proforma: action.payload.crud,
      }
    }

    case Actions.CRUD_PROFORMA_ITEM: {
      return {
        ...state,
        loading_crud_proforma_item: action.payload.loading,
        crud_proforma_item: action.payload.crud
      }
    }

    case Actions.GET_PROFORMA_ITEMS: {
      return {
        ...state,
        loading_proforma_items: action.payload.loading,
        proforma_items: action.payload.data
      }
    }

    case Actions.FINISH_PROFORMA_DETAIL: {
      return {
        ...state,
        loading_finish_proforma_detail: action.payload.loading,
        finish_proforma_detail: action.payload.crud,
      }
    }

    default: {
      return state
    }
  }
}

export default proformaReducer
