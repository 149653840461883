/*
    Componente: AppSuspense
    Descripción: Intervalo de tiempo antes de que aparezca la página principal del administrator (timeout)
*/

import React from "react";
import PropTypes from "prop-types";
import AppLoading from "./AppLoading";

const AppSuspense = (props) => {
    return (
        <React.Suspense fallback={<AppLoading {...props.loadingProps} />}>
            {props.children}
        </React.Suspense >
    );
};

AppSuspense.propTypes = {
    loadingProps: PropTypes.object,
};

AppSuspense.defaultProps = {
    loadingProps: {
        delay: 300
    }
};

export default AppSuspense;