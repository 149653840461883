/**
 * Valida si la entrada es un número y es mayor que 0.
 *
 * @param {any} input - la entrada a validar
 * @return {boolean} true si la entrada es un número mayor que 0, false en caso contrario
 */
export const validateIsNumber = (input) => {
    const num = Number(input)
    if (typeof num !== 'number' || isNaN(num) || num === null || num === undefined) {
        return false
    }
    return num > 0
}