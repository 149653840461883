import * as Actions from "../../actions/app"

const initialState = {
  // orden de servicio
  loading_service_orders: false,
  service_orders: null,
  total_pages_service_orders: 1,
  total_registers_service_orders: 0,
  loading_service_order: false,
  service_order: null,
  loading_crud_service_order: false,
  crud_service_order: false,

  // detalle orden de servicio
  loading_details_service_order_by_group: false,
  details_service_order_by_group: null,
  loading_crud_detail_service_order: false,
  crud_detail_service_order: false,

  // ordenes de tecnico
  service_orders_tech: null,
  total_pages_service_orders_tech: 1,
  total_registers_service_orders_tech: 0,
}

const vehicleServiceOrderReducer = function (state = initialState, action) {
  switch (action.type) {
    // orden de servicio
    case Actions.LOADING_SERVICE_ORDERS: {
      return {
        ...state,
        loading_service_orders: action.payload,
      }
    }
    case Actions.GET_SERVICE_ORDERS: {
      return {
        ...state,
        service_orders: action.payload.detalles,
        total_pages_service_orders: action.payload.totalPaginas,
        total_registers_service_orders: action.payload.totalRegistros,
      }
    }
    case Actions.GET_SERVICE_ORDER: {
      return {
        ...state,
        loading_service_order: action.payload.loading,
        service_order: action.payload.data,
      }
    }
    case Actions.CRUD_SERVICE_ORDER: {
      return {
        ...state,
        loading_crud_service_order: action.payload.loading,
        crud_service_order: action.payload.crud,
      }
    }

    // detalle orden de servicio
    case Actions.GET_DETAILS_SERVICE_ORDER_BY_GROUP: {
      return {
        ...state,
        loading_details_service_order_by_group: action.payload.loading,
        details_service_order_by_group: action.payload.data,
      }
    }
    case Actions.CRUD_DETAIL_SERVICE_ORDER: {
      return {
        ...state,
        loading_crud_detail_service_order: action.payload.loading,
        crud_detail_service_order: action.payload.crud,
      }
    }


    // ordenes de tecnico
    case Actions.GET_SERVICE_ORDERS_TECH: {
      return {
        ...state,
        service_orders_tech: action.payload.detalles,
        total_pages_service_orders_tech: action.payload.totalPaginas,
        total_registers_service_orders_tech: action.payload.totalRegistros,
      }
    }

    default: {
      return state
    }
  }
}
export default vehicleServiceOrderReducer
