/*
    Servicio: authService
    Descripción: Servicio de autenticación de usuario que se ejecuta cada vez que la página refresa para validar la expiración del token y el token mismo, así como
                 el inicio de sesión con las credenciales de usuario ingresadas
*/

import AppUtils from "@app/AppUtils"
import axios from "axios"
import jwtDecode from "jwt-decode"

class authService extends AppUtils.EventEmitter {
  init() {
    this.setInterceptors()
    this.handleAuthentication()
  }

  handleAuthentication = () => {
    let access_token = this.getAccessToken()

    if (!access_token) {
      this.emit("onNoAccessToken")

      return
    }

    if (this.isAuthTokenValid(access_token)) {
      this.emit("onAutoLogin", true)
    } else {
      this.setSession(null)
      this.emit("onAutoLogout", "access_token expired")
    }
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response?.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            this.emit("onAutoLogout", "Invalid access_token")
            this.setSession(null)
          }
          throw err
        })
      }
    )
  }

  signInWithUsernameAndPassword = (username, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/login`, {
          username: username,
          password: password,
        })
        .then((response) => {
          if (response && response.data.user) {
            response.data.user.role = "admin"

            this.setSession(response.data.token)
            resolve(response.data.user)
          } else {
            reject(response.data.error)
          }
        }).catch((error) => {
          reject("Usuario y/o contraseña inválidos")
        })
    })
  }

  signInWithToken = (path = "undefined") => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/validate-token`)
        .then((response) => {
          if (response && response.data.valid) {
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/autenticado/${path}`)
              .then((response) => {
                const tUser = [response.data.user]

                tUser.role = "admin"
                resolve(tUser)
              })
          } else {
            this.logout()
            reject("Ha ocurrido un error al iniciar sesión con el token")
          }
        })
        .catch((error) => {
          this.logout()
          reject("Ha ocurrido un error al iniciar sesión con el token")
        })
    })
  }

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem("access_token", access_token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token
    } else {
      localStorage.removeItem("access_token")
      delete axios.defaults.headers.common["Authorization"]
    }
  }

  logout = () => {
    this.setSession(null)
  }

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false
    }

    const decoded = jwtDecode(access_token)
    const currentTime = Date.now() / 1000
    if (decoded.exp < currentTime) {
      console.warn("access token expired")
      return false
    } else {
      return true
    }
  }

  getAccessToken = () => {
    return localStorage.getItem("access_token")
  }
}

const instance = new authService()
export default instance
