import * as Actions from "../../actions/app"

const initialState = {

  loan_opening_incomes: null,
  total_pages_loan_opening_incomes: 1,
  total_registers_loan_opening_incomes: 0,

  loading_crud_loan_opening_income: false,
  crud_loan_opening_income: false,

  loan_opening_expenses: null,
  total_pages_loan_opening_expenses: 1,
  total_registers_loan_opening_expenses: 0,

  loading_crud_loan_opening_expense: false,
  crud_loan_opening_expense: false,

}

const loanOpeningMovementsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_LOAN_OPENING_INCOMES: {
      return {
        ...state,
        loan_opening_incomes: action.payload.detalles,
        total_pages_loan_opening_incomes: action.payload.totalPaginas,
        total_registers_loan_opening_incomes: action.payload.totalRegistros
      }
    }

    case Actions.CRUD_LOAN_OPENING_INCOME: {
      return {
        ...state,
        loading_crud_loan_opening_income: action.payload.loading,
        crud_loan_opening_income: action.payload.crud,
      }
    }

    case Actions.GET_LOAN_OPENING_EXPENSES: {
      return {
        ...state,
        loan_opening_expenses: action.payload.detalles,
        total_pages_loan_opening_expenses: action.payload.totalPaginas,
        total_registers_loan_opening_expenses: action.payload.totalRegistros
      }
    }

    case Actions.CRUD_LOAN_OPENING_EXPENSE: {
      return {
        ...state,
        loading_crud_loan_opening_expense: action.payload.loading,
        crud_loan_opening_expense: action.payload.crud,
      }
    }

    default: {
      return state
    }
  }
}

export default loanOpeningMovementsReducer
