import React from 'react'
import { AuthRoles } from "components/auth";

export const BuysConfig = {
    auth: AuthRoles.admin,
    routes: [
        {
            path: `${process.env.PUBLIC_URL}/buys`,
            component: React.lazy(() => import('./Buys'))
        }
    ]
}
