import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"

export const LOADING_SERVICE_ORDERS = "[SERVICE ORDERS] LOADING SERVICE ORDERS"
export const GET_SERVICE_ORDERS = "[SERVICE ORDERS] GET SERVICE ORDERS"
export const GET_SERVICE_ORDER = "[SERVICE ORDERS] GET SERVICE ORDER"
export const CRUD_SERVICE_ORDER = "[SERVICE ORDERS] CRUD SERVICE ORDER"

export const GET_DETAILS_SERVICE_ORDER_BY_GROUP = "[SERVICE ORDERS] GET DETAIL SERVICE ORDER BY GROUP"
export const CRUD_DETAIL_SERVICE_ORDER = "[SERVICE ORDERS] CRUD DETAIL SERVICE ORDER"

export const GET_SERVICE_ORDERS_TECH = "[SERVICE ORDERS] GET SERVICE ORDERS TECH"

// ordenes de servicio

export function getServiceOrders(page = 1) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/ordenesservicio?page=${page}`)
  return dispatch => {
    dispatch({ type: LOADING_SERVICE_ORDERS, payload: true })
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_SERVICE_ORDERS, payload: response.data })
      dispatch({ type: LOADING_SERVICE_ORDERS, payload: false })
      return
    }).catch(error => {
      dispatch({ type: LOADING_SERVICE_ORDERS, payload: false })
    })
  }
}

export function getServiceOrderById(orderId, showLoading = true) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/gordenservicio/${orderId}`)
  return (dispatch) => {
    dispatch({ type: GET_SERVICE_ORDER, payload: { loading: true } })
    if (showLoading) toast.info("Obteniendo orden de servicio...", { toastId: "getServiceOrderById", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_SERVICE_ORDER, payload: { loading: false, data: response.data.detalles } })
        toast.dismiss("getServiceOrderById")
      } else {
        dispatch({ type: GET_SERVICE_ORDER, payload: { loading: false, data: null } })
        toast.update("getServiceOrderById", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: GET_SERVICE_ORDER, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_SERVICE_ORDER, payload: { loading: false, data: null } })
      toast.update("getServiceOrderById", { autoClose: 4000, type: "error", render: "Error al obtener la orden de servicio" })
      console.log(error)
    })
  }
}

export function saveServiceOrder(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rordenservicio`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: true } })
    toast.info("Guardando orden de servicio...", { toastId: "saveServiceOrder", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: false, crud: true } })
        toast.update("saveServiceOrder", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("saveServiceOrder", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: false, crud: false } })
      toast.update("saveServiceOrder", { autoClose: 4000, type: "error", render: "Error al guardar la orden de servicio" })
      console.log(error)
    })
  }
}

export function updateServiceOrder(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/aordenservicio`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: true } })
    toast.info("Actualizando orden de servicio...", { toastId: "updateServiceOrder", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: false, crud: true } })
        toast.update("updateServiceOrder", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("updateServiceOrder", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: false, crud: false } })
      toast.update("updateServiceOrder", { autoClose: 4000, type: "error", render: "Error al actualizar la orden de servicio" })
      console.log(error)
    })
  }
}

export function updateStatusServiceOrder(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cordenservicio`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: true } })
    toast.info("Actualizando estado...", { toastId: "updateStatusServiceOrder", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: false, crud: true } })
        toast.update("updateStatusServiceOrder", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("updateStatusServiceOrder", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: false, crud: false } })
      toast.update("updateStatusServiceOrder", { autoClose: 4000, type: "error", render: "Error al actualizar estado" })
      console.log(error)
    })
  }
}

export function deleteVehicleServiceOrder(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/eordenservicio`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: true } })
    toast.info("Eliminando orden de servicio...", { toastId: "deleteVehicleServiceOrder", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: false, crud: true } })
        toast.update("deleteVehicleServiceOrder", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("deleteVehicleServiceOrder", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_SERVICE_ORDER, payload: { loading: false, crud: false } })
      toast.update("deleteVehicleServiceOrder", { autoClose: 4000, type: "error", render: "Error al eliminar la orden de servicio" })
      console.log(error)
    })
  }
}


// detalle orden servicio

export function getDetailsServiceOrderByGroup({ orderId = 0, group = 1, }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/dosrgrupo?idorden=${orderId}&grupo=${group}`)
  return (dispatch) => {
    dispatch({ type: GET_DETAILS_SERVICE_ORDER_BY_GROUP, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_DETAILS_SERVICE_ORDER_BY_GROUP, payload: { loading: false, data: response.data.detalles } })
      } else {
        dispatch({ type: GET_DETAILS_SERVICE_ORDER_BY_GROUP, payload: { loading: false, data: null } })
      }
      return setTimeout(() => {
        dispatch({ type: GET_DETAILS_SERVICE_ORDER_BY_GROUP, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_DETAILS_SERVICE_ORDER_BY_GROUP, payload: { loading: false, data: null } })
      toast.error("Error al obtener los detalles de la orden de servicio")
      console.log(error)
    })
  }
}

export function saveDetailServiceOrder(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rdosr`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_DETAIL_SERVICE_ORDER, payload: { loading: true } })
    toast.info("Guardando registro...", { toastId: "saveDetailServiceOrder", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_DETAIL_SERVICE_ORDER, payload: { loading: false, crud: true } })
        toast.update("saveDetailServiceOrder", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("saveDetailServiceOrder", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_DETAIL_SERVICE_ORDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_DETAIL_SERVICE_ORDER, payload: { loading: false, crud: false } })
      toast.update("saveDetailServiceOrder", { autoClose: 4000, type: "error", render: "Error al guardar el registro" })
      console.log(error)
    })
  }
}

export function updateStatusDetailServiceOrder(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cdosr`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_DETAIL_SERVICE_ORDER, payload: { loading: true } })
    toast.info("Actualizando estado...", { toastId: "updateStatusDetailServiceOrder", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_DETAIL_SERVICE_ORDER, payload: { loading: false, crud: true } })
        toast.update("updateStatusDetailServiceOrder", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("updateStatusDetailServiceOrder", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_DETAIL_SERVICE_ORDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_DETAIL_SERVICE_ORDER, payload: { loading: false, crud: false } })
      toast.update("updateStatusDetailServiceOrder", { autoClose: 4000, type: "error", render: "Error al actualizar el estado" })
      console.log(error)
    })
  }
}

export function deleteDetailServiceOrder(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/edosr`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_DETAIL_SERVICE_ORDER, payload: { loading: true } })
    toast.info("Borrando registro...", { toastId: "deleteDetailServiceOrder", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_DETAIL_SERVICE_ORDER, payload: { loading: false, crud: true } })
        toast.update("deleteDetailServiceOrder", { autoClose: 4000, type: "success", render: response.data.mensaje })
      } else {
        toast.update("deleteDetailServiceOrder", { autoClose: 4000, type: "error", render: response.data.mensaje })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_DETAIL_SERVICE_ORDER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_DETAIL_SERVICE_ORDER, payload: { loading: false, crud: false } })
      toast.update("deleteDetailServiceOrder", { autoClose: 4000, type: "error", render: "Error al borrar el registro" })
      console.log(error)
    })
  }
}


// ordenes tecnicos

export function getServiceOrdersTech(techId, page = 1) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/ordenesserviciotecnico/${techId}?page=${page}`)
  return dispatch => {
    request.then(response => {
      if (parseInt(response.data.status) === 404) {
        if ((localStorage.getItem('access_token'))) {
          localStorage.removeItem('access_token')
          delete axios.defaults.headers.common['Authorization']
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_SERVICE_ORDERS_TECH, payload: response.data })
      return
    }).catch(error => {
      console.log(error)
    })
  }
}