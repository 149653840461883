/*
    Configuración: routesConfig
    Descripción: Archivo de configuración de las rutas de la aplicación
*/

import React from 'react';
import { Redirect } from 'react-router-dom';
import AppUtils from '@app/AppUtils';

import { LoginConfig } from 'components/pages/login/LoginConfig';
import { ProductsConfig } from 'components/pages/products/ProductsConfig';
import { DashboardConfig } from 'components/pages/dashboard/DashboardConfig';
import { CustomersProvidersConfig } from 'components/pages/customers_providers/CustomersProvidersConfig';
import { VouchersConfig } from 'components/pages/vouchers/VouchersConfig';
import { EstablishmentsConfig } from 'components/pages/establishments/EstablishmentsConfig';
import { UsersConfig } from 'components/pages/users/UsersConfig';
import { SalesConfig } from 'components/pages/sales/SalesConfig';
import { OrdersConfig } from 'components/pages/orders/OrdersConfig';
import { ProformasConfig } from 'components/pages/proformas/ProformasConfig';
import { ManageCashConfig } from 'components/pages/manage_cash/ManageCashConfig';
import { AccessControlConfig } from 'components/pages/access_control/AccessControlConfig';
import { ExpensesReceiptConfig } from 'components/pages/expenses_receipt/ExpensesReceiptConfig';
import { IncomesReceiptConfig } from 'components/pages/income_receipt/IncomesReceiptConfig';
import { ManageCpeConfig } from 'components/pages/manage_cpe/ManageCpeConfig';
import { CreditNotesConfig } from 'components/pages/credit_notes/CreditNotesConfig';
import { ElectronicGuidesConfig } from 'components/pages/electronic_guide/ElectronicGuidesConfig';
import { MenuControlConfig } from 'components/pages/menu_control/MenuControlConfig';
import { ManagementDriverConfig } from 'components/pages/management_driver/ManagementDriverConfig';
import { ReportSalesConfig } from 'components/pages/report_sales/ReportSalesConfig';
import { BuysConfig } from 'components/pages/buys/BuysConfig';
import { VehiclesConfig } from 'components/pages/vehicles/VehiclesConfig';
import { VehicleRequirementsConfig } from 'components/pages/vehicle_requirements/VehicleRequirementsConfig';
import { VehicleOrderServicesConfig } from 'components/pages/vehicle_service_orders/VehicleOrderServicesConfig';
import { EmployeesConfig } from 'components/pages/employees/EmployeesConfig';
import { AttentionServiceOrdersConfig } from 'components/pages/attention_service_orders/AttentionServiceOrdersConfig';
import { VehicleStationsConfig } from 'components/pages/vehicle_stations/VehicleStationsConfig';
import { ReportServiceOrdersConfig } from 'components/pages/report_service_orders/ReportServiceOrdersConfig';
import { AccountsPayableConfig } from 'components/pages/accounts_payable/AccountsPayableConfig';
import { AccountsReceivableConfig } from 'components/pages/accounts_receivable/AccountsReceivableConfig';
import { DispatchesConfig } from 'components/pages/dispatches/DispatchesConfig';
import { EntriesConfig } from 'components/pages/entries/EntriesConfig';
import { OutputsConfig } from 'components/pages/outputs/OutputsConfig';
import { PricesProductsConfig } from 'components/pages/prices_products/PricesProductsConfig';
import { OperatingExpensesConfig } from 'components/pages/operating_expenses/OperatingExpensesConfig';
import { LoansConfig } from 'components/pages/loans/LoansConfig';
import { StoresConfig } from 'components/pages/stores/StoresConfig';
import { ComplaintsBookConfig } from 'components/pages/complaints_book/ComplaintsBookConfig';
import { EstablishmentsComplaintsConfig } from 'components/pages/establishments_complaints/EstablishmentsComplaintsConfig';
import { TransfersConfig } from 'components/pages/transfers/TransfersConfig';
import { LoansOpeningConfig } from 'components/pages/loans_opening/LoansOpeningConfig';
import { LoanOpeningIncomesConfig } from 'components/pages/loan_opening_incomes/LoanOpeningIncomesConfig';
import { LoanOpeningExpensesConfig } from 'components/pages/loan_opening_expenses/LoanOpeningExpensesConfig';
import { LoansReportConfig } from 'components/pages/loans_report/LoansReportConfig';
import { InventoryConfig } from '../components/pages/inventory/InventoryConfig';
import { RequirementsConfig } from '../components/pages/requirements/RequirementsConfig';
import { SalesReportConfig } from '../components/pages/sales_report/SalesReportConfig';

const routesConfig = [
  LoginConfig,
  ProductsConfig,
  DashboardConfig,
  CustomersProvidersConfig,
  VouchersConfig,
  EstablishmentsConfig,
  UsersConfig,
  SalesConfig,
  OrdersConfig,
  ProformasConfig,
  ManageCashConfig,
  AccessControlConfig,
  ExpensesReceiptConfig,
  IncomesReceiptConfig,
  ManageCpeConfig,
  CreditNotesConfig,
  ElectronicGuidesConfig,
  MenuControlConfig,
  ManagementDriverConfig,
  ReportSalesConfig,
  BuysConfig,
  VehiclesConfig,
  EmployeesConfig,
  VehicleRequirementsConfig,
  VehicleOrderServicesConfig,
  AttentionServiceOrdersConfig,
  VehicleStationsConfig,
  ReportServiceOrdersConfig,
  AccountsPayableConfig,
  AccountsReceivableConfig,
  DispatchesConfig,
  EntriesConfig,
  OutputsConfig,
  PricesProductsConfig,
  OperatingExpensesConfig,
  LoansConfig,
  StoresConfig,
  ComplaintsBookConfig,
  EstablishmentsComplaintsConfig,
  TransfersConfig,
  LoansOpeningConfig,
  LoanOpeningIncomesConfig,
  LoanOpeningExpensesConfig,
  LoansReportConfig,
  InventoryConfig,
  RequirementsConfig,
  SalesReportConfig,
];

const routes = [
  ...AppUtils.generateRoutesFromConfigs(routesConfig, null),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />,
  },
  {
    component: () => <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />,
  },
];

export default routes;
