import * as Actions from "../../actions/app"

const initialState = {

  loading_crud_inventory_photo: false,
  crud_inventory_photo: false,

  loading_crud_inventory_obs: false,
  crud_inventory_obs: false,

  loading_crud_inventory_accessory: false,
  crud_inventory_accessory: false,

  loading_accessories_inventory: false,
  accessories_inventory: null,

}

const vehiclesInventoriesReducer = function (state = initialState, action) {
  switch (action.type) {

    case Actions.CRUD_INVENTORY_PHOTO: {
      return {
        ...state,
        loading_crud_inventory_photo: action.payload.loading,
        crud_inventory_photo: action.payload.crud,
      }
    }
    case Actions.CRUD_INVENTORY_OBSERVATION: {
      return {
        ...state,
        loading_crud_inventory_obs: action.payload.loading,
        crud_inventory_obs: action.payload.crud,
      }
    }
    case Actions.CRUD_INVENTORY_ACCESSORY: {
      return {
        ...state,
        loading_crud_inventory_accessory: action.payload.loading,
        crud_inventory_accessory: action.payload.crud,
      }
    }

    case Actions.GET_ACCESORIES_INVENTORY: {
      return {
        ...state,
        loading_accessories_inventory: action.payload.loading,
        accessories_inventory: action.payload.data,
      }
    }

    default: {
      return state
    }
  }
}
export default vehiclesInventoriesReducer
