import React from "react"
import { AuthRoles } from "components/auth"

export const TransfersConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/transfers`,
      component: React.lazy(() => import("./Transfers")),
    },
  ],
}
