import React from 'react'
import { AuthRoles } from "components/auth"

export const UsersConfig = {
    auth: AuthRoles.admin,
    routes: [
        {
            path: `${process.env.PUBLIC_URL}/users`,
            component: React.lazy(() => import('./Users'))
        }
    ]
}