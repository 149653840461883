import React from "react"
import { AuthRoles } from "components/auth"

export const VehicleStationsConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/stations`,
      component: React.lazy(() => import("./VehicleStations")),
    },
  ],
}
