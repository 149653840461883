import axios from "axios"
import * as Actions from "components/auth/store/actions"
import { toast } from "react-toastify"

export const GET_ALL_OPENINGS = "[MANAGE CASH] GET ALL OPENINGS"

export const CRUD_OPENING = "[MANAGE CASH] CRUD OPENING"

export const GET_OPENING_AMOUNTS = "[MANAGE CASH] OPENING AMOUNTS"

export const GET_OPENING_INCOMES_AND_EXPENSES = "[MANAGE CASH] GET OPENING INCOMES AND EXPENSES"

export function getAllOpenings({ userId = 0, branchId = 0 }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/apertura?idusuario=${userId}&idsucursal=${branchId}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_ALL_OPENINGS, payload: response.data.detalles })
    })
}

export function saveOpening(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/caja-apertura`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_OPENING, payload: { loading: true, crud: false } })
    toast.info('Registrando apertura...', { toastId: 'saveOpening', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: true } })
        toast.update('saveOpening', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('saveOpening', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('saveOpening', { render: "Error al registrar apertura", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function getOpeningAmounts({ openingId = 0 }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/caja-importes?idcaja=${openingId}`)
  return (dispatch) => {
    dispatch({ type: GET_OPENING_AMOUNTS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 400) {
        dispatch({ type: GET_OPENING_AMOUNTS, payload: { loading: false, data: null } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: GET_OPENING_AMOUNTS, payload: { loading: false, data: response.data.detalles } })
      return

    }).catch((error) => {
      dispatch({ type: GET_OPENING_AMOUNTS, payload: { loading: false, data: null } })
      toast.error("Error al cargar importes de caja")
      console.log(error)
    })
  }
}

export function getOpeningIncomesAndExpenses({ openingId = 0 }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/caja-ingresos-egresos?idcaja=${openingId}`)
  return (dispatch) => {
    dispatch({ type: GET_OPENING_INCOMES_AND_EXPENSES, payload: { loading: true, data: null } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_OPENING_INCOMES_AND_EXPENSES, payload: { loading: false, data: response.data.detalles } })
    }).catch((error) => {
      console.log(GET_OPENING_INCOMES_AND_EXPENSES, error)
      dispatch({ type: GET_OPENING_INCOMES_AND_EXPENSES, payload: { loading: false, data: null } })
    })
  }
}

export function closeOpening(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/caja-cierre`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_OPENING, payload: { loading: true, crud: false } })
    toast.info('Cerrando caja...', { toastId: 'closeOpening', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: true } })
        toast.update('closeOpening', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('closeOpening', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('closeOpening', { render: "Error al registrar el cierre de caja", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function revertOpeningClosed({ openingId = 0 }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/caja-reversion?idcaja=${openingId}`)
  return (dispatch) => {
    dispatch({ type: CRUD_OPENING, payload: { loading: true, crud: false } })
    toast.info('Reviertiendo cierre de caja...', { toastId: 'revertOpeningClosed', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: true } })
        toast.update('revertOpeningClosed', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('revertOpeningClosed', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('revertOpeningClosed', { render: "Error al revertir cierre de caja", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function deleteOpening(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/caja-eliminacion`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_OPENING, payload: { loading: true, crud: false } })
    toast.info('Eliminando caja...', { toastId: 'deleteOpening', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: true } })
        toast.update('deleteOpening', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('deleteOpening', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('deleteOpening', { render: "Error al eliminar caja", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_OPENING, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}