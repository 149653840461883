import axios from 'axios';
import { toast } from 'react-toastify';
import * as Actions from 'components/auth/store/actions';

export const GET_MOTIVES = '[ENTRIES AND OUTPUTS] GET MOTIVES';

// entradas

export const GET_ENTRIES = '[ENTRIES] GET ENTRIES';

export const GET_ENTRY = '[ENTRIES] GET ENTRY';

export const CRUD_ENTRY_HEADER = '[ENTRIES] CRUD ENTRY HEADER';

export const CRUD_ENTRY = '[ENTRIES] CRUD ENTRY';

export const CRUD_ENTRY_ITEM = '[ENTRIES] CRUD ENTRY ITEM';

export const GET_ENTRY_ITEMS = '[ENTRIES] GET ENTRY ITEMS';

export const FINISH_ENTRY_DETAIL = '[ENTRIES] FINISH ENTRY DETAIL';

// salidas

export const GET_OUTPUTS = '[OUTPUTS] GET OUTPUTS';

export const GET_OUTPUT = '[OUTPUTS] GET OUTPUT';

export const CRUD_OUTPUT_HEADER = '[OUTPUTS] CRUD OUTPUT HEADER';

export const CRUD_OUTPUT = '[OUTPUTS] CRUD OUTPUT';

export const CRUD_OUTPUT_ITEM = '[OUTPUTS] CRUD OUTPUT ITEM';

export const GET_OUTPUT_ITEMS = '[OUTPUTS] GET OUTPUT ITEMS';

export const FINISH_OUTPUT_DETAIL = '[OUTPUTS] FINISH OUTPUT DETAIL';

export function getMotives({ motiveType = 0 }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/motivo?tipomotivo=${motiveType}`
  );
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem('access_token')) {
          localStorage.removeItem('access_token');
          delete axios.defaults.headers.common['Authorization'];
          return dispatch(Actions.logoutUser());
        }
        return;
      }
      dispatch({ type: GET_MOTIVES, payload: response.data.detalles });
    });
}

// entradas

export function getEntries({ page = 1, userId = 0, branchId = 0 }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/entrada?page=${page}&idusuario=${userId}&idsucursal=${branchId}`
  );
  return (dispatch) => {
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        dispatch({ type: GET_ENTRIES, payload: response.data });
      })
      .catch((error) => {
        toast.error('Error inesperado. No se pudo obtener las entradas');
        console.log(error);
      });
  };
}

export function getEntry({ entryId }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/entrada-obtener?identrada=${entryId}`
  );
  return (dispatch) => {
    dispatch({ type: GET_ENTRY, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        dispatch({
          type: GET_ENTRY,
          payload: { loading: false, data: response.data.detalles },
        });
        setTimeout(() => {
          dispatch({
            type: GET_ENTRY,
            payload: { loading: false, data: null },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({ type: GET_ENTRY, payload: { loading: false, data: null } });
        toast.error('Error inesperado, no se pudo obtener la entrada');
        console.log(error);
      });
  };
}

export function saveEntryHeader(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/entrada/guardar-cabecera`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_ENTRY_HEADER, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status === 200) {
          dispatch({
            type: CRUD_ENTRY_HEADER,
            payload: {
              loading: false,
              crud: true,
              data: response.data.detalles,
            },
          });
          toast.success(response.data.mensaje);
        } else {
          toast.error(response.data.mensaje);
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_ENTRY_HEADER,
            payload: { loading: false, crud: false, data: null },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_ENTRY_HEADER,
          payload: { loading: false, crud: false, data: null },
        });
        toast.error('Error inesperado. No se pudo generar la entrada');
        console.log(error);
      });
  };
}

export function saveEntryItem(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/entrada-detalle/guardar-item`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_ENTRY_ITEM, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_ENTRY_ITEM,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje);
        } else {
          toast.error(response.data.mensaje);
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_ENTRY_ITEM,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_ENTRY_ITEM,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado. No se pudo guardar el item');
        console.log(error);
      });
  };
}

export function getEntryItems({ entryId = 0 }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/entrada-detalle/items?identrada=${entryId}`
  );
  return (dispatch) => {
    dispatch({ type: GET_ENTRY_ITEMS, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }

        dispatch({
          type: GET_ENTRY_ITEMS,
          payload: { loading: false, data: response.data.detalles },
        });

        setTimeout(() => {
          dispatch({
            type: GET_ENTRY_ITEMS,
            payload: { loading: false, data: null },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: GET_ENTRY_ITEMS,
          payload: { loading: false, data: null },
        });
        toast.error(
          'Error inesperado. No se pudo obtener los items de la entrada'
        );
        console.log(error);
      });
  };
}

export function deleteEntryItem(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/entrada-detalle/eliminar-item`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_ENTRY_ITEM, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_ENTRY_ITEM,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje);
        } else {
          toast.error(response.data.mensaje);
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_ENTRY_ITEM,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_ENTRY_ITEM,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado. No se pudo eliminar el item');
        console.log(error);
      });
  };
}

export function finishEntryDetail(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/entrada-detalle/finalizar-items`,
    form
  );
  return (dispatch) => {
    dispatch({ type: FINISH_ENTRY_DETAIL, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: FINISH_ENTRY_DETAIL,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje);
        } else {
          toast.error(response.data.mensaje);
        }
        setTimeout(() => {
          dispatch({
            type: FINISH_ENTRY_DETAIL,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: FINISH_ENTRY_DETAIL,
          payload: { loading: false, crud: false },
        });
        toast.error(
          'Error inesperado. No se pudo finalizar el detalle de la entrada'
        );
        console.log(error);
      });
  };
}

export function deleteEntry(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/entrada/eliminar-entrada`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_ENTRY, payload: { loading: true } });
    toast.info('Anulando entrada...', {
      toastId: 'deleteOutput',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_ENTRY,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje, {
            toastId: 'deleteOutput',
            autoClose: 5000,
          });
        } else {
          toast.error(response.data.mensaje, {
            toastId: 'deleteOutput',
            autoClose: 5000,
          });
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_ENTRY,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_ENTRY,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado, no se pudo anular la entrada', {
          toastId: 'deleteOutput',
        });
        console.log(error);
      });
  };
}

export function finishEntry(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/entrada/finalizar-entrada`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_ENTRY, payload: { loading: true } });
    toast.info('Finalizando entrada...', {
      toastId: 'finishEntry',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_ENTRY,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje, {
            toastId: 'finishEntry',
            autoClose: 5000,
          });
        } else {
          toast.error(response.data.mensaje, {
            toastId: 'finishEntry',
            autoClose: 5000,
          });
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_ENTRY,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_ENTRY,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado, no se pudo finalizar la entrada', {
          toastId: 'finishEntry',
        });
        console.log(error);
      });
  };
}

// salidas

export function getOutputs({ page = 1, userId = 0, branchId = 0 }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/salida?page=${page}&idusuario=${userId}&idsucursal=${branchId}`
  );
  return (dispatch) => {
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        dispatch({ type: GET_OUTPUTS, payload: response.data });
      })
      .catch((error) => {
        toast.error('Error inesperado. No se pudo obtener las salidas');
        console.log(error);
      });
  };
}

export function getOutput({ outputId }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/salida-obtener?idsalida=${outputId}`
  );
  return (dispatch) => {
    dispatch({ type: GET_OUTPUT, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        dispatch({
          type: GET_OUTPUT,
          payload: { loading: false, data: response.data.detalles },
        });
        setTimeout(() => {
          dispatch({
            type: GET_OUTPUT,
            payload: { loading: false, data: null },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({ type: GET_OUTPUT, payload: { loading: false, data: null } });
        toast.error('Error inesperado, no se pudo obtener la salida');
        console.log(error);
      });
  };
}

export function saveOutputHeader(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/salida/guardar-cabecera`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_OUTPUT_HEADER, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status === 200) {
          dispatch({
            type: CRUD_OUTPUT_HEADER,
            payload: {
              loading: false,
              crud: true,
              data: response.data.detalles,
            },
          });
          toast.success(response.data.mensaje);
        } else {
          toast.error(response.data.mensaje);
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_OUTPUT_HEADER,
            payload: { loading: false, crud: false, data: null },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_OUTPUT_HEADER,
          payload: { loading: false, crud: false, data: null },
        });
        toast.error('Error inesperado. No se pudo generar la salida');
        console.log(error);
      });
  };
}

export function saveOutputItem(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/salida-detalle/guardar-item`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_OUTPUT_ITEM, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_OUTPUT_ITEM,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje);
        } else {
          toast.error(response.data.mensaje);
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_OUTPUT_ITEM,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_OUTPUT_ITEM,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado. No se pudo guardar el item');
        console.log(error);
      });
  };
}

export function getOutputItems({ outputId = 0 }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/salida-detalle/items?idsalida=${outputId}`
  );
  return (dispatch) => {
    dispatch({ type: GET_OUTPUT_ITEMS, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }

        dispatch({
          type: GET_OUTPUT_ITEMS,
          payload: { loading: false, data: response.data.detalles },
        });

        setTimeout(() => {
          dispatch({
            type: GET_OUTPUT_ITEMS,
            payload: { loading: false, data: null },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: GET_OUTPUT_ITEMS,
          payload: { loading: false, data: null },
        });
        toast.error(
          'Error inesperado. No se pudo obtener los items de la salida'
        );
        console.log(error);
      });
  };
}

export function deleteOutputItem(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/salida-detalle/eliminar-item`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_OUTPUT_ITEM, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_OUTPUT_ITEM,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje);
        } else {
          toast.error(response.data.mensaje);
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_OUTPUT_ITEM,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_OUTPUT_ITEM,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado. No se pudo eliminar el item');
        console.log(error);
      });
  };
}

export function finishOutputDetail(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/salida-detalle/finalizar-items`,
    form
  );
  return (dispatch) => {
    dispatch({ type: FINISH_OUTPUT_DETAIL, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: FINISH_OUTPUT_DETAIL,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje);
        } else {
          toast.error(response.data.mensaje);
        }
        setTimeout(() => {
          dispatch({
            type: FINISH_OUTPUT_DETAIL,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: FINISH_OUTPUT_DETAIL,
          payload: { loading: false, crud: false },
        });
        toast.error(
          'Error inesperado. No se pudo finalizar el detalle de la salida'
        );
        console.log(error);
      });
  };
}

export function deleteOutput(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/salida/eliminar-salida`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_OUTPUT, payload: { loading: true } });
    toast.info('Anulando salida...', {
      toastId: 'deleteOutput',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_OUTPUT,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje, {
            toastId: 'deleteOutput',
            autoClose: 5000,
          });
        } else {
          toast.error(response.data.mensaje, {
            toastId: 'deleteOutput',
            autoClose: 5000,
          });
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_OUTPUT,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_OUTPUT,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado, no se pudo anular la salida', {
          toastId: 'deleteOutput',
        });
        console.log(error);
      });
  };
}

export function finishOutput(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/salida/finalizar-salida`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_OUTPUT, payload: { loading: true } });
    toast.info('Finalizando salida...', {
      toastId: 'finishOutput',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_OUTPUT,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje, {
            toastId: 'finishOutput',
            autoClose: 5000,
          });
        } else {
          toast.error(response.data.mensaje, {
            toastId: 'finishOutput',
            autoClose: 5000,
          });
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_OUTPUT,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_OUTPUT,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado, no se pudo finalizar la salida', {
          toastId: 'finishOutput',
        });
        console.log(error);
      });
  };
}

export function saveOutputFromOrder(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/salida/guardar-desde-pedido`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_OUTPUT, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status === 200) {
          dispatch({
            type: CRUD_OUTPUT,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje);
        } else {
          toast.error(response.data.mensaje);
        }
        return setTimeout(() => {
          dispatch({
            type: CRUD_OUTPUT,
            payload: { loading: false, crud: false },
          });
        }, 50);
      })
      .catch((error) => {
        dispatch({
          type: CRUD_OUTPUT,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado. No se pudo guardar la salida');
        console.log(error);
      });
  };
}
