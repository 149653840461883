import React from "react"
import { AuthRoles } from "components/auth"

export const ReportSalesConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/report_sales`,
      component: React.lazy(() => import("./ReportSales")),
    },
  ],
}
