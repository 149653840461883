
/**
 * Valida la entrada para asegurarse de que sea un número con hasta dos decimales.
 *
 * @param {string} input - la entrada a validar 
 * @return {string} la entrada validada
 */
export const validateInputNumberTwoDecimals = (input) => {
    const regex = /^(?:0|[1-9]\d*)(?:\.\d{0,2})?$/
    return regex.test(input) ? input : input.slice(0, -1)
}

/**
 * Valida la entrada para asegurarse de que sea un número con hasta 3 decimales.
 *
 * @param {string} input - la entrada a validar 
 * @return {string} la entrada validada
 */
export const validateInputNumberThreeDecimals = (input) => {
    const regex = /^(?:0|[1-9]\d*)(?:\.\d{0,3})?$/
    return regex.test(input) ? input : input.slice(0, -1)
}

/**
 * Valida la entrada para asegurarse de que sea un número con hasta 4 decimales.
 *
 * @param {string} input - la entrada a validar 
 * @return {string} la entrada validada
 */
export const validateInputNumberFourDecimals = (input) => {
    const regex = /^(?:0|[1-9]\d*)(?:\.\d{0,4})?$/
    return regex.test(input) ? input : input.slice(0, -1)
}