import { format, parseISO } from 'date-fns'
import { enUS, es } from 'date-fns/locale'

/**
 * Formatea la fecha de cadena dada según el tipo especificado e incluye la hora si se especifica.
 *
 * @param {string} dateString - La cadena de fecha a formatear.
 * @param {string} [type='EN'] - El tipo de formato de fecha ('EN' o 'en' para YYYY-MM-DD, o 'ES' o 'es' para DD/MM/YYYY).
 * @param {boolean} [withHours=false] - Si incluir la hora en la fecha formateada.
 * @return {string} La cadena de fecha formateada.
 */
export const formatStringDate = (dateString, type = 'EN', withHours = false, friendly = false) => {
  if (!dateString) return ''

  const dateObject = parseISO(dateString)

  if (isNaN(dateObject.getTime())) return ''

  let formattedDate = ''

  const english = type === 'EN' || type === 'en'
  const spanish = type === 'ES' || type === 'es'

  if (english) {
    if (!friendly) formattedDate = format(dateObject, 'yyyy-MM-dd')
    else formattedDate = format(dateObject, "dd MMMM, yyyy", { locale: enUS })
  }

  if (spanish) {
    if (!friendly) formattedDate = format(dateObject, 'dd/MM/yyyy')
    else formattedDate = format(dateObject, "dd 'de' MMMM, yyyy", { locale: es })
  }

  if (withHours) {
    if (friendly) {

      if (english) formattedDate += ` at ${format(dateObject, 'HH:mm a')}`
      if (spanish) formattedDate += ` a las ${format(dateObject, 'HH:mm a')}`

    } else {

      formattedDate += `, ${format(dateObject, 'HH:mm a')}`

    }
  }

  return formattedDate
}