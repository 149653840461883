import React from 'react';
import { AuthRoles } from "components/auth";

export const LoansReportConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/loans_report`,
      component: React.lazy(() => import('./LoansReport'))
    }
  ]
};