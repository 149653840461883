import * as Actions from "../../actions/app"

const initialState = {

  incomes: null,
  total_pages_incomes: 1,
  total_registers_incomes: 0,

  expenses: null,
  total_pages_expenses: 1,
  total_registers_expenses: 0,

  loading_crud_receipt: false,
  crud_receipt: false

}

const incomesAndExpensesReducer = function (state = initialState, action) {
  switch (action.type) {

    case Actions.GET_INCOMES: {
      return {
        ...state,
        incomes: action.payload.detalles,
        total_pages_incomes: action.payload.totalPaginas,
        total_registers_incomes: action.payload.totalRegistros,
      }
    }
    case Actions.GET_EXPENSES: {
      return {
        ...state,
        expenses: action.payload.detalles,
        total_pages_expenses: action.payload.totalPaginas,
        total_registers_expenses: action.payload.totalRegistros,
      }
    }
    case Actions.CRUD_INCOME_OR_EXPENSE: {
      return {
        ...state,
        loading_crud_receipt: action.payload.loading,
        crud_receipt: action.payload.crud
      }
    }
    default: {
      return state
    }
  }
}
export default incomesAndExpensesReducer
