import * as Actions from "../../actions/app"

const initialState = {

  loans_opening: null,
  total_pages_loans_opening: 1,
  total_registers: 0,

  loading_crud_loan_opening: false,
  crud_loan_opening: false,

  loading_loan_opening_loans: false,
  loan_opening_loans: null,

  loading_loan_opening_movements: false,
  loan_opening_movements: null,

  loading_loan_opening_payments: false,
  loan_opening_payments: null,

}

const loansOpeningReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_LOANS_OPENING: {
      return {
        ...state,
        loans_opening: action.payload.detalles,
        total_pages_loans_opening: action.payload.totalPaginas,
        total_registers: action.payload.totalRegistros
      }
    }

    case Actions.CRUD_LOAN_OPENING: {
      return {
        ...state,
        loading_crud_loan_opening: action.payload.loading,
        crud_loan_opening: action.payload.crud,
      }
    }

    case Actions.GET_LOAN_OPENING_LOANS: {
      return {
        ...state,
        loading_loan_opening_loans: action.payload.loading,
        loan_opening_loans: action.payload.data
      }
    }

    case Actions.GET_LOAN_OPENING_MOVEMENTS: {
      return {
        ...state,
        loading_loan_opening_movements: action.payload.loading,
        loan_opening_movements: action.payload.data
      }
    }

    case Actions.GET_LOAN_OPENING_PAYMENTS: {
      return {
        ...state,
        loading_loan_opening_payments: action.payload.loading,
        loan_opening_payments: action.payload.data
      }
    }

    default: {
      return state
    }
  }
}

export default loansOpeningReducer
