import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"

export const GET_ALL_SERIES = "[SERIES] GET ALL SERIES"

export const CRUD_SERIE = "[SERIES] CRUD SERIE"

export function getAllSeries() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/serie`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_ALL_SERIES, payload: response.data.detalles })
      return
    }).catch((err) => {
      dispatch({ type: GET_ALL_SERIES, payload: null })
      toast.error("Error inesperado. No se pudo obtener las series")
      console.log(err)
    })
}

export function saveSerie(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rserie`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_SERIE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_SERIE, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_SERIE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_SERIE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo registrar la serie")
      console.log(err)
    })
  }
}

export function updateSerie(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/aserie`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_SERIE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_SERIE, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_SERIE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_SERIE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo actualizar la serie")
      console.log(err)
    })
  }
}

export function deleteSerie(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/eserie`, form)
  return (dispatch) => {
    toast.info("Borrando serie...", { toastId: "deleteSerie", autoClose: false })
    dispatch({ type: CRUD_SERIE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_SERIE, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje, { toastId: "deleteSerie", autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: "deleteSerie", autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_SERIE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_SERIE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo borrar la serie", { toastId: "deleteSerie", autoClose: 5000 })
      console.log(err)
    })
  }
}

export function updateSerieStatus(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cserie`, form)
  return (dispatch) => {
    toast.info("Actualizando estado...", { toastId: "updateSerieStatus", autoClose: false })
    dispatch({ type: CRUD_SERIE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_SERIE, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje, { toastId: "updateSerieStatus", autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: "updateSerieStatus", autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_SERIE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_SERIE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo actualizar el estado de la serie", { toastId: "updateSerieStatus", autoClose: 5000 })
      console.log(err)
    })
  }
}
