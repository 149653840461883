import React from 'react';
import { AuthRoles } from "components/auth";

export const EntriesConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/entries`,
      component: React.lazy(() => import('./Entries'))
    }
  ]
};