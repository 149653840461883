import * as Actions from "../../actions/app"

const initialState = {
  vehicle_stations: null,
  loading_crud_vehicle_station: false,
  crud_vehicle_station: false,
}

const vehicleStationsReducer = function (state = initialState, action) {
  switch (action.type) {

    case Actions.GET_ALL_VEHICLE_STATIONS: {
      return {
        ...state,
        vehicle_stations: action.payload,
      }
    }

    case Actions.CRUD_VEHICLE_STATION: {
      return {
        ...state,
        loading_crud_vehicle_station: action.payload.loading,
        crud_vehicle_station: action.payload.crud,
      }
    }

    default: {
      return state
    }
  }
}
export default vehicleStationsReducer
