import * as Actions from "../../actions/app"

const initialState = {

  openings: null,
  loading_crud_opening: false,
  crud_opening: false,

  loading_opening_amounts: false,
  opening_amounts: null,

  loading_products_sold_opening: false,
  products_sold_opening: null,

  loading_totals_formspay_opening: false,
  totals_formspay_opening: null,

  loading_opening_incomes_and_expenses: false,
  opening_incomes_and_expenses: null,
}
const manageCashReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ALL_OPENINGS: {
      return {
        ...state,
        openings: action.payload
      }
    }
    case Actions.CRUD_OPENING: {
      return {
        ...state,
        loading_crud_opening: action.payload.loading,
        crud_opening: action.payload.crud,
      }
    }
    case Actions.GET_OPENING_AMOUNTS: {
      return {
        ...state,
        loading_opening_amounts: action.payload.loading,
        opening_amounts: action.payload.data
      }
    }
    case Actions.GET_OPENING_INCOMES_AND_EXPENSES: {
      return {
        ...state,
        loading_opening_incomes_and_expenses: action.payload.loading,
        opening_incomes_and_expenses: action.payload.data
      }
    }
    default: {
      return state
    }
  }
}
export default manageCashReducer
