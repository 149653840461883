import React from 'react';
import { AuthRoles } from "components/auth";

export const PricesProductsConfig = {
    auth: AuthRoles.admin,
    routes: [
        {
            path: `${process.env.PUBLIC_URL}/prices_products`,
            component: React.lazy(() => import('./PricesProducts'))
        }
    ]
};