import React from "react"
import { AuthRoles } from "components/auth"

export const ComplaintsBookConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/complaints_book`,
      component: React.lazy(() => import("./ComplaintsBook")),
    },
  ],
}
