import * as Actions from "../../actions/app"

const initialState = {
  employees: null,
  loading_crud_employee: false,
  crud_employee: false,
  employees_with_user: null,
}

const employeeReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ALL_EMPLOYEES: {
      return {
        ...state,
        employees: action.payload,
      }
    }
    case Actions.CRUD_EMPLOYEE: {
      return {
        ...state,
        loading_crud_employee: action.payload.loading,
        crud_employee: action.payload.crud,
      }
    }
    case Actions.GET_EMPLOYEES_WITH_USER: {
      return {
        ...state,
        employees_with_user: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
export default employeeReducer
