import * as Actions from "../../actions/app"

const initialState = {
  vouchers: null,

  default_voucher: null,

  loading_crud_voucher: false,
  crud_voucher: false,

  vouchers_for_sale: null,
}

const voucherReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ALL_VOUCHERS: {
      return {
        ...state,
        vouchers: action.payload
      }
    }

    case Actions.GET_DEFAULT_VOUCHER: {
      return {
        ...state,
        default_voucher: action.payload,
      }
    }

    case Actions.CRUD_VOUCHER: {
      return {
        ...state,
        loading_crud_voucher: action.payload.loading,
        crud_voucher: action.payload.crud
      }
    }

    case Actions.GET_VOUCHERS_FOR_SALE: {
      return {
        ...state,
        vouchers_for_sale: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default voucherReducer
