import React from 'react';
import { AuthRoles } from 'components/auth';

export const RequirementsConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/requirements`,
      component: React.lazy(() => import('./Requirements.js')),
    },
  ],
};
