import * as Actions from "../../actions/app"

const initialState = {

  loading_crud_header_transfer: false,
  crud_header_transfer: false,
  data_crud_header_transfer: null,

  loading_transfers: false,
  transfers: null,

  loading_transfers_by_store: false,
  transfers_by_store: null,

  loading_reject_or_accept_transfer: false,
  reject_or_accept_transfer: false,

  loading_crud_transfer_item: false,
  crud_transfer_item: false,

  loading_transfer_items: false,
  transfer_items: null,

  loading_finish_transfer_detail: false,
  finish_transfer_detail: false,

}

const transfersReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CRUD_HEADER_TRANSFER: {
      return {
        ...state,
        loading_crud_header_transfer: action.payload.loading,
        crud_header_transfer: action.payload.crud,
        data_crud_header_transfer: action.payload.data,
      }
    }

    case Actions.GET_TRANSFERS: {
      return {
        ...state,
        loading_transfers: action.payload.loading,
        transfers: action.payload.data,
      }
    }

    case Actions.GET_TRANSFERS_BY_STORE: {
      return {
        ...state,
        loading_transfers_by_store: action.payload.loading,
        transfers_by_store: action.payload.data,
      }
    }

    case Actions.REJECT_OR_ACCEPT_TRANSFER: {
      return {
        ...state,
        loading_reject_or_accept_transfer: action.payload.loading,
        reject_or_accept_transfer: action.payload.crud,
      }
    }

    case Actions.CRUD_TRANSFER_ITEM: {
      return {
        ...state,
        loading_crud_transfer_item: action.payload.loading,
        crud_transfer_item: action.payload.crud,
      }
    }

    case Actions.GET_TRANSFER_ITEMS: {
      return {
        ...state,
        loading_transfer_items: action.payload.loading,
        transfer_items: action.payload.data,
      }
    }

    case Actions.FINISH_TRANSFER_DETAIL: {
      return {
        ...state,
        loading_finish_transfer_detail: action.payload.loading,
        finish_transfer_detail: action.payload.crud,
      }
    }

    default: {
      return state
    }
  }
}
export default transfersReducer
