import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"

export const GET_ALL_USERS = "[USERS] GET ALL USERS"

export const CRUD_USER = "[USERS] CRUD USER"

export const GET_USER_SERIES = "[USERS] GET USER SERIES"

export const CRUD_USER_SERIE = "[USERS] CRUD USER SERIE"

export function getAllUsers() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/usuario`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_ALL_USERS, payload: response.data.detalles })
    }).catch((err) => {
      toast.error("Error al obtener los usuarios")
      console.log(err)
    })
}

export function saveUser(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rusuario`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_USER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_USER, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_USER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_USER, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo guardar el usuario")
      console.log(err)
    })
  }
}

export function deleteUser(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/eusuario`, from)
  return (dispatch) => {
    toast.info("Eliminando usuario...", { toastId: "deleteUser", autoClose: false })
    dispatch({ type: CRUD_USER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_USER, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje, { toastId: "deleteUser", autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: "deleteUser", autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_USER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_USER, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo eliminar al usuario", { toastId: "deleteUser", autoClose: 5000 })
      console.log(err)
    })
  }
}

export function updateUserStatus(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cusuario`, form)
  return (dispatch) => {
    toast.info("Actualizando estado...", { toastId: "updateUserStatus", autoClose: false })
    dispatch({ type: CRUD_USER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_USER, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje, { toastId: "updateUserStatus", autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: "updateUserStatus", autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_USER, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_USER, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo actualizar el estado del usuario", { toastId: "updateUserStatus", autoClose: 5000 })
      console.log(err)
    })
  }
}

export function getUserSeries({ userId = 0 }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/serie-usuario?idusuario=${userId}`)
  return (dispatch) => {
    dispatch({ type: GET_USER_SERIES, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) === 200) {
        dispatch({ type: GET_USER_SERIES, payload: { loading: false, data: response.data.detalles } })
      } else {
        dispatch({ type: GET_USER_SERIES, payload: { loading: false, data: null } })
      }

      setTimeout(() => {
        dispatch({ type: GET_USER_SERIES, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_USER_SERIES, payload: { loading: false, data: null } })
      toast.error("Error al obtener las series del usuario")
      console.log(error)
    })
  }
}

export function saveUserSerie(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/serie-usuario/guardar`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_USER_SERIE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_USER_SERIE, payload: { loading: false, crud: true } })
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_USER_SERIE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_USER_SERIE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo asignar la serie al usuario")
      console.log(err)
    })
  }
}

export function deleteUserSerie(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/serie-usuario/eliminar`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_USER_SERIE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_USER_SERIE, payload: { loading: false, crud: true } })
      } else {
        toast.error(response.data.mensaje)
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_USER_SERIE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((err) => {
      dispatch({ type: CRUD_USER_SERIE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo eliminar la serie del usuario")
      console.log(err)
    })
  }
}


