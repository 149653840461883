import { addDays, format, parseISO } from 'date-fns'

/**
 * Función que suma días (números enteros) a una fecha.
 *
 * @param {string} dateString - La cadena de fecha a la que se sumarán los días.
 * @param {number} days - Número de días a sumar.
 * @return {string} La nueva fecha en formato 'YYYY-MM-DD'.
 */
export const getDateMoreDays = (dateString, days = 0) => {
    if (!dateString) return ''
    const dateObject = addDays(parseISO(dateString), days)
    return format(dateObject, 'yyyy-MM-dd')
}