import React from 'react'
import ReactDOM from 'react-dom'
import "bootswatch/dist/yeti/bootstrap.min.css"

import 'antd/dist/antd.css'

import App from './App'

import 'react-bootstrap-typeahead/css/Typeahead.css'
import "react-datepicker/dist/react-datepicker.css"

import { registerLocale } from "react-datepicker"
import es from 'date-fns/locale/es'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'

import './index.css'

registerLocale('es', es)

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
)

serviceWorker.unregister()
