import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as userActions from 'components/auth/store/actions';
import { bindActionCreators } from 'redux';
import authService from 'services/authService';
import Loader from 'components/pages/helpers/loader/Loader';

class Auth extends Component {
    state = {
        waitAuthCheck: true
    }

    componentDidMount() {

        return Promise.all([
            this.tokenCheck()
        ]).then(() => {
            this.setState({ waitAuthCheck: false })
        })
    }

    tokenCheck = () => new Promise(resolve => {
        authService.on('onAutoLogin', () => {
            authService.signInWithToken((this.props.location.pathname.substring(1).length == 0) ? undefined : this.props.location.pathname.substring(1))
                .then(user => {
                    this.props.setUserData(user);
                    resolve();
                })
                .catch(error => {
                    resolve();
                })
        });

        authService.on('onAutoLogout', (message) => {
            this.props.logout();

            resolve();
        });

        authService.on('onNoAccessToken', () => {

            resolve();
        });

        authService.init();

        return Promise.resolve();
    })

    render() {
        return this.state.waitAuthCheck
            ? (
                <div>
                    <Loader show={true} center={true} />
                </div>
            ) : <React.Fragment children={this.props.children} />;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout: userActions.logoutUser,
        setUserData: userActions.setUserData,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(Auth);
