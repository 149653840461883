/**
 * Función para limpiar caracteres no deseados de una cadena.
 * Elimina los caracteres < > : " / \ | ? * ! de la cadena proporcionada.
 * @param {string} str - La cadena de la cual se eliminarán los caracteres no deseados.
 * @returns {string} - La cadena sin los caracteres no deseados.
 */

export const clearInvalidCharacters = (str) => {
  // Utiliza una expresión regular para reemplazar los caracteres no deseados
  return str.replace(/[<>:"/\\|?*!]/g, '')
}