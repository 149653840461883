import React from "react"
import { AuthRoles } from "components/auth"

export const ReportServiceOrdersConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/report_service_orders`,
      component: React.lazy(() => import("./ReportServiceOrders")),
    },
  ],
}
