import { validateIsNumber } from '../index'


/**
 * Checks if a specified number of minutes have passed since a given date.
 *
 * @param {string} date - The date to check, in string format.
 * @param {number} [minutes=1] - The number of minutes to check against. Defaults to 1 minute.
 * @returns {boolean} - Returns true if the specified number of minutes have passed, otherwise false.
 *
 */

export const hasMinutesPassed = (date, minutes = 1,) => {
  const dateObject = new Date(date);
  if (isNaN(dateObject.getTime())) {
    return false;
  }

  if (!validateIsNumber(minutes)) {
    return false;
  }

  const now = new Date();

  const difference = now - dateObject;

  const minutesTime = minutes * 60 * 1000;

  return difference > minutesTime;
}