
/**
 * Filters an array of rows based on a search query.
 *
 * @param {Array} rows - The array of rows to be filtered. Defaults to an empty array.
 * @param {string} inputValue - The search query. Defaults to an empty string.
 * @return {Array} The filtered array of rows.
 */

export const filterRegisters = (rows = [], inputValue = '') => {
  if (!inputValue) return rows
  let words = inputValue.toLowerCase().split(' ')

  return rows.filter(row =>
    words.every(
      word => Object.keys(row).some(key => String(row[key]).toLowerCase().includes(word))
    )
  )
}