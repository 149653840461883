import * as Actions from "../../actions/app"

const initialState = {

  loading_menus_by_user: false,
  menus_by_user: null,

  loading_crud_user_menus: false,
  crud_user_menus: false,

}

const accessControlReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_MENUS_BY_USER: {
      return {
        ...state,
        loading_menus_by_user: action.payload.loading,
        menus_by_user: action.payload.data,
      }
    }
    case Actions.CRUD_USER_MENUS: {
      return {
        ...state,
        loading_crud_user_menus: action.payload.loading,
        crud_user_menus: action.payload.crud,
      }
    }
    default: {
      return state
    }
  }
}

export default accessControlReducer
