import * as Actions from "../../actions/app"

const initialState = {
  list_drivers: null,

  loading_save: false,
  saved_driver: false,
  driver_saved: null,

  loading_update: false,
  updated_driver: false,

  deleted_driver: false
}

const managementDriver = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_LIST_DRIVERS: {
      return {
        ...state,
        list_drivers: action.payload
      }
    }
    case Actions.SAVE_DRIVER: {
      return {
        ...state,
        loading_save: action.payload.loading,
        saved_driver: action.payload.saved,
        driver_saved: action.payload.data,
      }
    }
    case Actions.UPDATE_DRIVER: {
      return {
        ...state,
        loading_update: action.payload.loading,
        updated_driver: action.payload.updated
      }
    }
    case Actions.DELETE_DRIVER: {
      return {
        ...state,
        deleted_driver: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default managementDriver
