import React from "react";
import { AuthRoles } from "components/auth";

export const AttentionServiceOrdersConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/attention_service_orders`,
      component: React.lazy(() => import("./AttentionServiceOrders")),
    },
  ],
};
