import axios from "axios"
import * as Actions from "components/auth/store/actions"

import { toast } from "react-toastify"

export const GET_LIST_MENUS = "[MENU CONTROL] GET LIST MENU CONTROL"

export const GET_LIST_MENUS_PADRES = "[MENU CONTROL] GET LIST MENU PADRES"

export const GET_LIST_MENUS_HIJOS = "[MENU CONTROL] GET LIST MENU HIJOS"

export const CRUD_MENU = "[MENU CONTROL] CRUD MENU CONTROL"

export function getMenuControl() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/menu`)

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_LIST_MENUS,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getMenuPadres() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/menupadres`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_LIST_MENUS_PADRES,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function getMenus() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/menus`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_LIST_MENUS_HIJOS,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      })
    })
}

export function saveMenu(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rmenu`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_MENU, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_MENU, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_MENU, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_MENU, payload: { loading: false, crud: false } })
      toast.error("Error al crear el menu")
      console.log(error)
    })
  }
}

export function updateMenu(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/amenu`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_MENU, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_MENU, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_MENU, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_MENU, payload: { loading: false, crud: false } })
      toast.error("Error al actualizar el menu")
      console.log(error)
    })
  }
}

export function deleteMenu(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/emenu`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_MENU, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_MENU, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_MENU, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_MENU, payload: { loading: false, crud: false } })
      toast.error("Error al eliminar el menu")
      console.log(error)
    })
  }
}
