import * as Actions from "../../actions/app"

const initialState = {

  establishment: null,
  set_crud_establishment: false,

  branches: null,
  crud_branch: false,

  crud_option_branch: false,

  bank_accounts: null,
  crud_bank_account: false,
}

const establishment = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ESTABLISHMENT: {
      return {
        ...state,
        establishment: action.payload,
      }
    }
    case Actions.GET_BRANCHES: {
      return {
        ...state,
        branches: action.payload,
      }
    }
    case Actions.CRUD_ESTABLISHMENT: {
      return {
        ...state,
        set_crud_establishment: action.payload,
      }
    }
    case Actions.CRUD_BRANCH: {
      return {
        ...state,
        crud_branch: action.payload,
      }
    }
    case Actions.CRUD_OPTIONS_BRANCH: {
      return {
        ...state,
        crud_option_branch: action.payload,
      }
    }
    case Actions.GET_BANK_ACCOUNTS: {
      return {
        ...state,
        bank_accounts: action.payload,
      }
    }
    case Actions.CRUD_BANK_ACCOUNT: {
      return {
        ...state,
        crud_bank_account: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
export default establishment
