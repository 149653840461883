import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"

export const GET_INCOMES = "[INCOMES AND EXPENSES] GET INCOMES"
export const GET_EXPENSES = "[INCOMES AND EXPENSES] GET EXPENSES"
export const CRUD_INCOME_OR_EXPENSE = "[INCOMES AND EXPENSES] CRUD INCOME OR EXPENSE"

export function getIncomes(userId = 0, page = 1) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/cajachica/I/${userId}?page=${page}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_INCOMES, payload: response.data })
    })
}

export function getExpenses(userId = 0, page = 1) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/cajachica/E/${userId}?page=${page}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({ type: GET_EXPENSES, payload: response.data })
    })
}

export function saveIncomeOrExpense(from) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/rcajachica`, from)
  return (dispatch) => {
    dispatch({ type: CRUD_INCOME_OR_EXPENSE, payload: { loading: true, crud: false } })
    toast.info('Guardando recibo...', { toastId: 'saveIncomeOrExpense', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_INCOME_OR_EXPENSE, payload: { loading: false, crud: true } })
        toast.update('saveIncomeOrExpense', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('saveIncomeOrExpense', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_INCOME_OR_EXPENSE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('saveIncomeOrExpense', { render: "Error al guardar el recibo...", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_INCOME_OR_EXPENSE, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}

export function deleteIncomeOrExpense(receiptId) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/ecajachica/${receiptId}`)
  return (dispatch) => {
    dispatch({ type: CRUD_INCOME_OR_EXPENSE, payload: { loading: true, crud: false } })
    toast.info('Eliminando recibo en caja chica...', { toastId: 'deleteIncomeOrExpense', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_INCOME_OR_EXPENSE, payload: { loading: false, crud: true } })
        toast.update('deleteIncomeOrExpense', { render: response.data.mensaje, type: "success", autoClose: 5000 })
      } else {
        toast.update('deleteIncomeOrExpense', { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      return setTimeout(() => {
        dispatch({ type: CRUD_INCOME_OR_EXPENSE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      toast.update('deleteIncomeOrExpense', { render: "Error al eliminar el recibo...", type: "error", autoClose: 5000 })
      dispatch({ type: CRUD_INCOME_OR_EXPENSE, payload: { loading: false, crud: false } })
      console.log(error)
    })
  }
}