import React from 'react';
import { AuthRoles } from "components/auth";

export const IncomesReceiptConfig = {
    auth: AuthRoles.admin,
    routes: [
        {
            path: `${process.env.PUBLIC_URL}/income_receipt`,
            component: React.lazy(() => import('./IncomesReceipt'))
        }
    ]
};