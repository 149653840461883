import React from 'react';
import { AuthRoles } from "components/auth";

export const OperatingExpensesConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/operating_expenses`,
      component: React.lazy(() => import('./OperatingExpenses'))
    }
  ]
};