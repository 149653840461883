import { toast } from "react-toastify";

export const clearCache = async () => {
  if ('caches' in window) {
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
    window.location.reload(true);
  } else {
    toast.error("No se pudo limpiar el caché (Navegador no soportado)");
  }
}