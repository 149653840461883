
export const filterProductsReactSelect = ({ data = {} }, inputValue = '') => {

  const words = inputValue.toLowerCase().split(' ')

  return words.every(palabra =>
    (data.denominacion || '').toLowerCase().includes(palabra) ||
    (data.denominacion_corta || '').toLowerCase().includes(palabra) ||
    (data.codigo_producto || '').toLowerCase().includes(palabra)
  )

}