import axios from "axios"
import { toast } from 'react-toastify'
import * as Actions from "components/auth/store/actions"
import { urlPdfs } from 'constants/index'
import { sendGuide } from "./manage_cpe.actions"

export const GET_GUIDE_TYPES = "[ELECTRONIC GUIDE] GET GUIDE TYPES"

export const GET_DEPARTAMENTS = "[ELECTRONIC GUIDE] GET DEPARTAMENTS"

export const GET_PROVINCES = "[ELECTRONIC GUIDE] GET PROVINCES"

export const GET_DISTRICTS = "[ELECTRONIC GUIDE] GET DISTRICTS"

export const GET_ELECTRONIC_GUIDES = "[ELECTRONIC GUIDE] GET ELECTRONIC GUIDES"

export const LOADING_ELECTRONIC_GUIDES = "[ELECTRONIC GUIDE] LOADING LIST ELECTRONIC GUIDES"

export const SAVE_ELECTRONIC_GUIDE = "[ELECTRONIC GUIDE] SAVE ELECTRONIC GUIDE"


export function getGuideTypes() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/tipoguia`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_GUIDE_TYPES,
        payload: response.data.detalles,
      })
    })
}

export function getDepartaments() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/departamentos`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DEPARTAMENTS,
        payload: response.data.detalles,
      })
    })
}

export function getProvinces() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/provincias`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_PROVINCES,
        payload: response.data.detalles,
      })
    })
}

export function getDistricts() {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/distritos`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DISTRICTS,
        payload: response.data.detalles,
      })
    })
}

export function getElectronicGuides({ page = 1, pageSize = 5, startDate = '', endDate = '', status = '', search = '', }) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}/api/guia-remision/registros`, {
    params: {
      page,
      por_pagina: pageSize,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      busqueda: search
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_ELECTRONIC_GUIDES, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_ELECTRONIC_GUIDES, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_ELECTRONIC_GUIDES, payload: { loading: false } })
      console.log(error);
    })
  }
}

export function saveElectronicGuide(form) {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/guia-remision/guardar`, form)
  return (dispatch) => {
    dispatch({ type: SAVE_ELECTRONIC_GUIDE, payload: { loading: true } })
    toast.info("Guardando guía...", { toastId: "saveGuia", autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        sendGuide({ idguia: response.data.detalles, refresh: false })
        dispatch({ type: SAVE_ELECTRONIC_GUIDE, payload: { loading: false, crud: true } })
        toast.update("saveGuia", { render: response.data.mensaje, type: "success", autoClose: 3000 })
        window.open(`${urlPdfs.guiaA4}/${response.data.detalles}`, "_blank")
      } else {
        toast.update("saveGuia", { render: response.data.mensaje, type: "error", autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: SAVE_ELECTRONIC_GUIDE, payload: { loading: false, crud: false } })
      }, 50)
    })
  }
}
