import Login from "./Login";
import { AuthRoles } from "components/auth";

export const LoginConfig = {
    auth: AuthRoles.onlyGuest,
    routes: [
        {
            path: `${process.env.PUBLIC_URL}/login`,
            component: Login
        }
    ]
};