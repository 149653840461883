import React from 'react';
import { AuthRoles } from "components/auth";

export const LoansOpeningConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/loans_opening`,
      component: React.lazy(() => import('./LoansOpening'))
    }
  ]
};