import React from 'react';
import axios from 'axios';
import history from '@history/index';
import routes from 'configs/routesConfig';
import AppContext from './AppContext';
import store from './store';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Flip, ToastContainer } from 'react-toastify';
import { Auth } from 'components/auth';

import AppAuthorization from '@app/components/AppAuthorization';
import AppLayout from '@app/AppLayout';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

if (localStorage.getItem('access_token')) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
}

const App = (props) => {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={store}>
        <Auth location={props.location}>
          <Router basename={'/trade'} history={history}>
            <AppAuthorization>
              <AppLayout />

              <ToastContainer
                position="top-center"
                autoClose={3500}
                hideProgressBar
                transition={Flip}
              />
            </AppAuthorization>
          </Router>
        </Auth>
      </Provider>
    </AppContext.Provider>
  );
};

export default withRouter(App);
