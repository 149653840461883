import * as Actions from "../../actions/app"

const initialState = {
  // marca:
  vehicles_brands: null,
  vehicle_brand: null,
  loading_crud_vehicle_brand: false,
  crud_vehicle_brand: false,

  // modelo:
  vehicles_models: null,
  vehicle_model: null,
  loading_crud_vehicle_model: false,
  crud_vehicle_model: false,

  // vehiculo:
  all_vehicles: null,
  vehicles_paginated: null,
  vehicles_with_requirements: null,
  total_pages_vehicles: 1,
  total_registers_vehicles: 0,
  loading_get_vehicle: false,
  vehicle: null,
  loading_crud_vehicle: false,
  crud_vehicle: false,

  // vehiculo propietarios
  loading_get_vehicle_owners: false,
  vehicle_owners: null,
  vehicle_owner: null,
  loading_crud_vehicle_owner: false,
  crud_vehicle_owner: false,

  // vehiculo accesorios
  all_vehicle_accessories: null,
  vehicle_accessory: null,
  loading_crud_vehicle_accessory: false,
  crud_vehicle_accessory: false,
}

const vechicleReducer = function (state = initialState, action) {
  switch (action.type) {
    // marcas vehiculos
    case Actions.GET_ALL_VEHICLES_BRANDS: {
      return {
        ...state,
        vehicles_brands: action.payload,
      }
    }
    case Actions.CRUD_VEHICLE_BRAND: {
      return {
        ...state,
        loading_crud_vehicle_brand: action.payload.loading,
        crud_vehicle_brand: action.payload.crud,
        vehicle_brand: action.payload.data,
      }
    }

    // modelos vehiculos
    case Actions.GET_ALL_VEHICLES_MODELS: {
      return {
        ...state,
        vehicles_models: action.payload,
      }
    }
    case Actions.CRUD_VEHICLE_MODEL: {
      return {
        ...state,
        loading_crud_vehicle_model: action.payload.loading,
        crud_vehicle_model: action.payload.crud,
        vehicle_model: action.payload.data,
      }
    }

    // vehicles
    case Actions.GET_ALL_VEHICLES: {
      return {
        ...state,
        all_vehicles: action.payload,
      }
    }
    case Actions.GET_VEHICLES_PAGINATED: {
      return {
        ...state,
        vehicles_paginated: action.payload.detalles,
        total_pages_vehicles: action.payload.totalPaginas,
        total_registers_vehicles: action.payload.totalRegistros,
      }
    }
    case Actions.GET_VEHICLES_WITH_REQUIREMENTS: {
      return {
        ...state,
        vehicles_with_requirements: action.payload,
      }
    }
    case Actions.GET_VEHICLE: {
      return {
        ...state,
        loading_get_vehicle: action.payload.loading,
        vehicle: action.payload.data,
      }
    }
    case Actions.CRUD_VEHICLE: {
      return {
        ...state,
        loading_crud_vehicle: action.payload.loading,
        crud_vehicle: action.payload.crud,
        vehicle: action.payload.data,
      }
    }

    // vehiculo propietarios
    case Actions.GET_VEHICLE_OWNERS: {
      return {
        ...state,
        loading_get_vehicle_owners: action.payload.loading,
        vehicle_owners: action.payload.data,
      }
    }
    case Actions.CRUD_VEHICLE_OWNER: {
      return {
        ...state,
        loading_crud_vehicle_owner: action.payload.loading,
        crud_vehicle_owner: action.payload.crud,
        vehicle_owner: action.payload.data,
      }
    }

    // accesorios vehiculos
    case Actions.GET_ALL_VEHICLE_ACCESSORIES: {
      return {
        ...state,
        all_vehicle_accessories: action.payload,
      }
    }
    case Actions.CRUD_VEHICLE_ACCESSORY: {
      return {
        ...state,
        loading_crud_vehicle_accessory: action.payload.loading,
        crud_vehicle_accessory: action.payload.crud,
        vehicle_accessory: action.payload.data,
      }
    }

    default: {
      return state
    }
  }
}
export default vechicleReducer
